<style scoped>
.nav-link:hover {
  cursor: pointer;
}

.sidebar {
  width: 260px;
  height: 100%;
}

.line-active {
  border-right: solid white 3px;
}

.sidebar,
.sub-menu {
  background-color: #343a40 !important;
}

.sidebar-dark,
.sub-menu-dark {
  background-color: var(--dark-main-color) !important;
}
.sub-menu .nav-link {
  color: white !important;
}
.nav-item,
.nav-link {
  border-radius: 0 !important;
  background-color: transparent !important;
}

.menu-title,
.menu-icon {
  color: #a1a2ac !important;
}

.line-active .menu-title,
.menu-icon {
  color: white !important;
}

.line-active .nav-link {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.nav-link:hover {
  background-color: rgba(202, 198, 198, 0.1) !important;
  transition: 0.4s !important;
}
</style>
<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <form action="" class="my-4" @submit.prevent="setDollarPrice()">
              <div class="form-group">
                <h4>{{ $t("menu.settings.dollarPriceTitle") }}</h4>
                <input
                  required
                  type="text"
                  class="form-control mt-3"
                  v-model.number="dollarPrice"
                />
              </div>
              <button type="submit" class="btn btn-primary btn-sm rounded-1">
                {{ $t("save") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <nav
      class="sidebar sidebar-offcanvas"
      id="sidebar"
      :class="isDarkMode ? 'sidebar-dark' : ''"
    >
      <ul class="nav">
        <li
        v-if="checkRootAdmin()"
          class="nav-item active"
          style="margin-top: 10px"
          :class="$route.name == 'home' ? 'line-active' : ''"
        >
          <router-link class="nav-link" to="/">
            <i class="mdi mdi-grid-large menu-icon"></i>
            <span class="menu-title">{{ $t("menu.dashboard") }}</span>
          </router-link>
        </li>
        <li
        v-if="checkRootAdmin()"
          class="nav-item active"
          style="margin-top: 10px"
          :class="$route.name == 'DailyReport' ? 'line-active' : ''"
        >
          <router-link class="nav-link" to="/reports/daily">
            <i class="mdi mdi-file-chart menu-icon"></i>
            <span class="menu-title">{{ $t("menu.dailyReports") }}</span>
          </router-link>
        </li>
        <li
        v-if="checkRootAdmin()"
          class="nav-item active"
          style="margin-top: 10px"
          :class="$route.name == 'SanadatList' ? 'line-active' : ''"
        >
          <router-link class="nav-link" to="/Bonds/List">
            <i class="mdi mdi-cash menu-icon"></i>
            <span class="menu-title">{{ $t("menu.sanadat") }}</span>
          </router-link>
        </li>
        <li
        v-if="checkRootAdmin()"
          class="nav-item active"
          :class="$route.params.type && $route.params.type == '3' ? 'line-active' : ''"
        >
          <router-link class="nav-link" to="/customers/3">
            <i class="mdi mdi-account-multiple menu-icon"></i>
            <span class="menu-title">{{ $t("menu.users") }}</span>
          </router-link>
        </li>
        <li
        v-if="checkRootAdmin()"
          class="nav-item active"
          :class="$route.params.type && $route.params.type == '5' ? 'line-active' : ''"
        >
          <router-link class="nav-link" to="/customers/5">
            <i class="mdi mdi-account-multiple menu-icon"></i>
            <span class="menu-title">{{ $t("menu.zabwn") }}</span>
          </router-link>
        </li>
        <li
        v-if="checkRootAdmin()"
          class="nav-item active"
          :class="$route.name == 'TasweqList' ? 'line-active' : ''"
        >
          <router-link class="nav-link" to="/Tasweq/List">
            <i class="mdi mdi-account-multiple menu-icon"></i>
            <span class="menu-title">{{ $t("menu.tasweq") }}</span>
          </router-link>
        </li>
        <li
        v-if="checkRootAdmin()"
          class="nav-item active"
          :class="$route.params.type && $route.params.type == '6' ? 'line-active' : ''"
        >
          <router-link class="nav-link" to="/customers/6">
            <i class="mdi mdi-bank-transfer menu-icon"></i>
            <span class="menu-title">{{ $t("menu.sairafa") }}</span>
          </router-link>
        </li>
        <li
          class="nav-item active"
          :class="$route.name == 'Flights' ? 'line-active' : ''"
        >
          <router-link class="nav-link" to="/Flights/List">
            <i class="mdi mdi-airplane menu-icon"></i>
            <span class="menu-title">{{ $t("flights") }}</span>
          </router-link>
        </li>
        <!-- <li
          class="nav-item active"
          :class="$route.name == 'YearsReport' ? 'line-active' : ''"
        >
          <a
            class="nav-link collapsed"
            data-bs-toggle="collapse"
            href="#charts"
            aria-expanded="false"
            aria-controls="charts"
          >
            <i class="menu-icon mdi mdi-chart-line"></i>
            <span class="menu-title">{{ $t("menu.reports.main") }}</span>
            <i class="menu-arrow" style="transform: scaleX(-1)"></i>
          </a>
          <div class="collapse" id="charts" style="">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link to="/Reports/Years" class="nav-link">{{
                  $t("menu.reports.months")
                }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/Reports/Daily" class="nav-link">{{
                  $t("menu.reports.daily")
                }}</router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/Reports/ExpiresToday"
                  class="nav-link justify-content-between"
                >
                  <span>{{ $t("menu.reports.expiresToday") }}</span>
                  <span
                    v-if="totalExpiresToday"
                    class="bg-danger text-light p-1 rounded-1"
                  >
                    {{ totalExpiresToday }}</span
                  >
                </router-link>
              </li>
            </ul>
          </div>
        </li> -->
        <li
        v-if="checkRootAdmin()"
          class="nav-item active"
          :class="
            $route.name == 'ExpenseType' && $route.params.isExpense == 1
              ? 'line-active'
              : ''
          "
        >
          <a
            class="nav-link collapsed"
            data-bs-toggle="collapse"
            href="#expense"
            aria-expanded="false"
            aria-controls="charts"
          >
            <i class="menu-icon mdi mdi-cash-multiple"></i>
            <span class="menu-title">{{ $t("menu.expenses.main") }}</span>
            <i class="menu-arrow" style="transform: scaleX(-1)"></i>
          </a>
          <div class="collapse" id="expense" style="">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link to="/Expenses/Daily/Create/true" class="nav-link">{{
                  $t("menu.expenses.daily")
                }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/Expenses/Monthly/List/true" class="nav-link">{{
                  $t("menu.expenses.monthly")
                }}</router-link>
              </li>
            </ul>
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link to="/ExpenseType/List/1" class="nav-link">{{
                  $t("menu.settings.expenseType")
                }}</router-link>
              </li>
            </ul>
          </div>
        </li>

        <li
          class="nav-item active"
          :class="$route.name == 'ChangePassword' ? 'line-active' : ''"
        >
          <a
            class="nav-link collapsed"
            data-bs-toggle="collapse"
            href="#settings"
            aria-expanded="false"
            aria-controls="charts"
          >
            <i class="menu-icon mdi mdi-settings"></i>
            <span class="menu-title">{{ $t("menu.settings.main") }}</span>
            <i class="menu-arrow" style="transform: scaleX(-1)"></i>
          </a>
          <div class="collapse" id="settings" style="">
            <ul
              class="nav flex-column sub-menu"
              :class="isDarkMode ? 'sub-menu-dark' : ''"
            >
              <li class="nav-item">
                <router-link to="/Settings/ChangePassword" class="nav-link">{{
                  $t("menu.settings.changePassword")
                }}</router-link>
              </li>
              <!-- <li class="nav-item" v-if="checkRootAdmin()">
                <a
                  role="button"
                  class="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  >{{ $t("menu.settings.dollarPrice") }}</a
                >
              </li> -->
            </ul>
          </div>
        </li>
        <li class="nav-item active">
          <a class="nav-link" role="button" @click="signOut">
            <i class="mdi mdi-logout menu-icon"></i>
            <span class="menu-title">{{ $t("menu.logout") }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { get, post } from "@/api";
export default {
  name: "SideBar",

  data() {
    return {
      totalExpiresToday: 0,
      dollarPrice: 0,
    };
  },
  computed: {
    ...mapState(["isDarkMode"]),
  },
  methods: {
    async setDollarPrice() {
      this.$loading(true);
      const URL = `/Api/Settings?newVal=${this.dollarPrice}`;
      await post(URL);
      await this.getDollarPrice();
      this.$loading(false);
    },
    async getDollarPrice() {
      const URL = "/Api/Settings";
      this.dollarPrice = await get(URL);
    },

    signOut() {
      this.$loading(true);
      setTimeout(() => {
        this.$session.destroy();
        this.$store.commit("logout");
        this.$store.commit("setUserLogOut");
        this.$router.push("/login");
        this.$loading(false);
      }, 400);
    },
    getTotalExpiresToday() {
      this.$Axios
        .get(
          `${
            this.$hostname
          }/api/userTransactions/generateExpiresTodayReport?date=${this.formatDatee(
            new Date()
          )}&isCount=true`,
          this.config()
        )
        .then((res) => {
          this.totalExpiresToday = res.data;
        })
        .catch((err) => {
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
  },
  async mounted() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }

    await this.getDollarPrice();

    //this.getTotalExpiresToday();
  },
};
</script>
