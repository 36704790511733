<style scoped>
.hama {
  background-color: red !important;
  display: none;
}
</style>

<template>
  <div>
    <Bar
      :data="chartData"
      :chart-data="chartData"
      :chart-id="123241"
      :width="100"
      :height="35"
      :styles="styles"
      :css-classes="cssClasses"
    />
  </div>
</template>
<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: ["chartData"],
  data() {
    return {
      cssClasses: "hama",
      styles: {
        fontFamily: "Arabic",
      },
      //   chartData: {
      //     labels: this.chartLabels,
      //     datasets: [
      //       {
      //         label: "Data One",
      //         backgroundColor: "#f87979",
      //         data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
      //       },
      //     ],
      //   },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {},
};
</script>
