<style scoped>
.date-btn:hover {
  cursor: pointer;
}
</style>
<template>
  <div v-if="days">
    <h1
      class="h3 mb-3"
      :class="
        $store.state.isDarkMode ? 'dark-active-text-color' : 'light-active-text-color'
      "
    >
      {{
        this.$route.params.isExpense == "true"
          ? $t("expenses.TitleMonth")
          : $t("imports.TitleMonth")
      }}
      {{ this.days[0].monthName }}
      {{ this.$route.params.year }}
    </h1>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-1">
          <div class="card-body">
            <div class="table-responsive" v-if="days">
              <table class="table table-hover my-0 table-striped">
                <thead style="background-color: #222e3c">
                  <tr class="text-light text-center">
                    <th>#</th>
                    <th>{{ $t("expenses.date") }}</th>
                    <th>{{ $t("expenses.totalDinnar") }}</th>
                    <th>{{ $t("expenses.totalDollar") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(day, index) in days"
                    :key="'day-' + index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      <router-link
                        class="date-btn text-primary"
                        style="text-decoration: none"
                        :to="`/Expenses/Daily/year/${day.year}/month/${day.month}/day/${day.day}/${$route.params.isExpense}`"
                        >{{ day.date }}</router-link
                      >
                    </td>
                    <td>
                      {{ day.totalDinnar.toLocaleString() }} {{ $t("currency.main") }}
                    </td>
                    <td>
                      {{ day.totalDollar.toLocaleString() }}
                      {{ $t("currency.secondary") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "@/api";
export default {
  name: "MonthDays",
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  data() {
    return {
      days: null,
    };
  },
  methods: {
    async loadDays() {
      this.$loading(true);
      const URL = `/api/Expenses/GetMonthDaysList?isExpense=${this.$route.params.isExpense}&year=${this.$route.params.year}&month=${this.$route.params.month}`;
      this.days = await get(URL);
      this.$loading(false);
    },
  },

  async mounted() {
    await this.loadDays();
  },
};
</script>
