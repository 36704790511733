<template>
  <div>
    <h1
      class="h3 mb-3"
      :class="
        $store.state.isDarkMode ? 'dark-active-text-color' : 'light-active-text-color'
      "
    >
      {{
        this.$route.params.isExpense == 1
          ? $t("expenseType.Title")
          : this.$t("importType.Title")
      }}
    </h1>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-1">
          <div class="card-body">
            <DataTable
              :table-config="expenseType"
              :modal-create-title="
                this.$route.params.isExpense == 1
                  ? $t('expenseType.add')
                  : $t('importType.add')
              "
              :modal-update-title="
                this.$route.params.isExpense == 1
                  ? $t('expenseType.update')
                  : $t('importType.update')
              "
            ></DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/DataTable.vue";
export default {
  name: "CompaniesView",
  components: {
    DataTable,
  },
  data() {
    return {
      showModal: false,
      expenseType: {
        url: `/api/ExpenseTypes`,
        fields: [
          {
            title:
              this.$route.params.isExpense == 1
                ? this.$t("expenseType.Title")
                : this.$t("importType.Title"),
            name: "title",
            type: "string",
            visible: true,
          },
        ],
      },
    };
  },
  watch: {
    async $route() {
      this.expenseType.fields[0].title =
        this.$route.params.isExpense == 1
          ? this.$t("expenseType.Title")
          : this.$t("importType.Title");
    },
  },
  methods: {},
  mounted() {},
};
</script>
