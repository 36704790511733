<style scoped>
.table td,
th {
    padding: 10px;
    text-align: center;
}

.bg-specific {
    background-color: #1F3BB3;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 5px;
}

.reportBtns button {
    margin-right: 5px;
}

.paymentFilterDropDown {
    height: 1.9rem;
    appearance: auto;
    padding: 2px;
    margin-top: 3px;


}
</style>

<template>
    <div>
        <div class="card rounded-2">
            <div class="card-header">
                <h4 class="card-title mt-2 mb-2"> {{ $t("customersExpiresToday") }} &nbsp;&nbsp;{{
                    formatDatee(new
                        Date()) }}</h4>
            </div>
            <div class="card-body">

                <div class="table-responsive">
                    <table class="table table-striped table-sm">
                        <thead class="bg-dark text-light">
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    {{ $t("customersTable.name") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.phone") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.month") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.amount") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.paymentType") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.renewDate") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.expireDate") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.paidDate") }}
                                </th>

                                <th>
                                    {{ $t("customersTable.view") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(transaction, index) in expiresTodayDate" :key="transaction.id">
                                <td class="py-1">
                                    {{ (index + 1) }}
                                </td>
                                <td>
                                    {{ transaction.user.fullName }}
                                </td>
                                <td>
                                    {{ transaction.user.phoneNumber }}
                                </td>
                                <td>
                                    {{ transaction.month }} - {{ transaction.year }}
                                </td>
                                <td>
                                    {{ transaction.amount.toLocaleString('en-US') }} <span>{{ $t("currency.main") }}
                                    </span>
                                </td>
                                <td>
                                    <div :class="getPaymentTextClass(transaction.paymentType)" class="badge me-3">
                                        {{ getPaymentText(transaction.paymentType) }}</div>
                                </td>
                                <td>
                                    {{ cleanDate(transaction.renewDate) }}
                                </td>
                                <td>
                                    {{ cleanDate(transaction.expireDate) }}
                                </td>
                                <td>
                                    {{ cleanDate(transaction.paidDate) }}
                                </td>

                                <td class="text-center">
                                    <router-link :to="`/Customer/Profile/${transaction.userId}`"> <i
                                            class="mdi mdi-eye big-icon"></i></router-link>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <!-- <h4 v-else class="text-center text-secondary">{{ $t("noData") }}</h4> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'ExpiresTodayReport',

    created()
    {
        if (!this.checkLogin())
        {
            this.$router.push("/login");
            return
        }
        //this.getExpiresTodayReport();
    },
    data()
    {
        return {
            expiresTodayDate: null
        }
    },
    methods: {

        getExpiresTodayReport()
        {
            this.$loading(true);
            this.$Axios.get(`${this.$hostname}/api/userTransactions/generateExpiresTodayReport?date=${this.formatDatee(new Date())}`, this.config())
                .then((res) =>
                {
                    this.expiresTodayDate = res.data
                    this.$loading(false);
                })
                .catch((err) =>
                {
                    this.$loading(false);
                    this.$toast.open({
                        message: err.toString(),
                        type: 'error',
                    });
                });
        },
    }

}
</script>