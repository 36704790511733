<style scoped>
.remove-top-margin {
  margin-top: -15px;
}
</style>

<template>
  <div>
    <h1 class="h3 mb-3">
      {{ $t("passengers") }} الرحلة {{ cleanDate($route.params.date) }}
    </h1>
    <!-- Make Payment Modal -->
    <div
      class="modal fade"
      id="exampleModalMakePayment"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <form class="forms-sampleee my-2" @submit.prevent="makeTransaction()">
              <div class="row">
                <div class="col col-lg-3 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername1">{{ $t("passenger.name") }}</label>
                    <input
                      disabled
                      v-model="paymentBody.name"
                      type="text"
                      class="form-control"
                      id="exampleInputUsername1"
                      required
                    />
                  </div>
                </div>
                <div class="col col-lg-2 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername12">{{
                      $t("passenger.paymentAmount")
                    }}</label>
                    <input
                      v-model="paymentBody.amount"
                      type="number"
                      step="any"
                      class="form-control"
                      id="exampleInputUsername12"
                    />
                  </div>
                </div>
                <div class="col col-lg-1 col-12">
                  <div class="form-group">
                    <label>{{ $t("currencyType") }}</label>
                    <select
                      class="form-control text-dark"
                      v-model.number="paymentBody.currencyType"
                      disabled
                    >
                      <option value="1">{{ $t("paymentTypeOptions.dinnar") }}</option>
                      <option value="2">{{ $t("paymentTypeOptions.dollar") }}</option>
                    </select>
                  </div>
                </div>
                <div class="col col-lg-2 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername14">{{ $t("passenger.date") }}</label>
                    <input
                      v-model="paymentBody.date"
                      type="date"
                      class="form-control"
                      id="exampleInputUsername14"
                      required
                    />
                  </div>
                </div>
                <div class="col col-lg-3 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername155">{{
                      $t("passenger.note")
                    }}</label>
                    <input
                      v-model="paymentBody.note"
                      class="form-control"
                      id="exampleInputUsername155"
                    />
                  </div>
                </div>
                <div class="col col-lg-1 col-12">
                  <div class="form-group">
                    <label style="visibility: hidden">{{ $t("passenger.note") }}</label>
                    <button
                      type="submit"
                      class="btn btn-primary btn-sm rounded-1 me-2"
                      :disabled="isLoading"
                    >
                      <div
                        class="spinner-border text-light ml-3"
                        v-if="isLoading"
                        role="status"
                      >
                        <span class="visually-hidden"></span>
                      </div>
                      <span>{{ paymentBody.id == 0 ? $t("submit") : $t("update") }}</span>
                    </button>
                  </div>
                </div>
              </div>

              <button
                data-bs-dismiss="modal"
                aria-label="Close"
                id="paymentModalCloseBtn"
                class="btn btn-light btn-sm rounded-1 d-none"
              >
                {{ $t("close") }}
              </button>
            </form>
            <hr />
            <div class="table-responsive mt-2">
              <table class="table table-striped text-center" v-if="passengerTransactions">
                <thead class="bg-dark text-light">
                  <tr class="text-center">
                    <th>#</th>
                    <th>
                      {{ $t("passengerTransaction.amountPaid") }}
                    </th>
                    <th>
                      {{ $t("passengerTransaction.date") }}
                    </th>

                    <th>
                      {{ $t("addUserForm.note") }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(transaction, index) in passengerTransactions"
                    :key="transaction.id"
                  >
                    <td class="py-1">
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ transaction.amount.toLocaleString() }}
                      {{ getCurrencyText(transaction.currencyType) }}
                    </td>
                    <td>{{ cleanDate(transaction.date) }}</td>

                    <td>{{ transaction.note }}</td>

                    <td>
                      <button
                        class="btn btn-primary btn-sm rounded-1 mx-1 my-1"
                        @click="assignDataTransaction(transaction)"
                      >
                        {{ $t("edit") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <form class="forms-sample" @submit.prevent="postPassenger()">
              <div class="row">
                <div class="col col-lg-4 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername1">{{ $t("passenger.name") }}</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                      id="exampleInputUsername1"
                      required
                    />
                  </div>
                </div>
                <div class="col col-lg-4 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername12">{{
                      $t("passenger.phoneNumber")
                    }}</label>
                    <input
                      v-model="data.phoneNumber"
                      type="text"
                      class="form-control"
                      id="exampleInputUsername12"
                    />
                  </div>
                </div>
                <div class="col col-lg-4 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername12M">{{
                      $t("passenger.motherName")
                    }}</label>
                    <input
                      v-model="data.motherName"
                      type="text"
                      class="form-control"
                      id="exampleInputUsername12M"
                    />
                  </div>
                </div>
                <div class="col col-lg-3 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername12PN">{{
                      $t("passenger.passPortNo")
                    }}</label>
                    <input
                      v-model="data.passportNo"
                      type="text"
                      class="form-control"
                      id="exampleInputUsername12PN"
                    />
                  </div>
                </div>
                <div class="col col-lg-3 col-12">
                  <div class="form-group">
                    <label class="d-block" for="exampleInputUsername12px">{{
                      $t("passenger.passportExpireDate")
                    }}</label>
                    <date-picker
                      style="width: 100%"
                      :clearable="false"
                      v-model="data.passportExpireDate"
                      type="date"
                      format="DD/MM/YYYY"
                      valueType="format"
                    ></date-picker>
                   
                  </div>
                </div>
                
                <div class="col col-lg-3 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername12EnL">{{
                      $t("passenger.enLastName")
                    }}</label>
                    <input
                      v-model="data.enLastName"
                      type="text"
                      class="form-control"
                      id="exampleInputUsername12EnL"
                    />
                  </div>
                </div>
                <div class="col col-lg-3 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername12EnF">{{
                      $t("passenger.enFirstName")
                    }}</label>
                    <input
                      v-model="data.enFirstName"
                      type="text"
                      class="form-control"
                      id="exampleInputUsername12EnF"
                    />
                  </div>
                </div>
                <div class="col col-lg-3 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername12DOB">{{
                      $t("passenger.dateOfBirth")
                    }}</label>
                    <date-picker
                    style="width: 100%"
                    :clearable="false"
                    v-model="data.dateOfBirth"
                    type="date"
                    format="DD/MM/YYYY"
                    valueType="format"
                  ></date-picker>
                   
                  </div>
                </div>
                <div class="col col-lg-3 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername12Gender">{{
                      $t("passenger.gender")
                    }}</label>
                    <select
                      class="form-control text-dark"
                      v-model="data.gender"
                      id="exampleInputUsername12Gender"
                    >
                      <option value="MALE">ذكر</option>
                      <option value="FEMALE">انثى</option>
                      <option value="طفل">طفل</option>
                      <option value="رضيع">رضيع</option>
                    </select>
                  </div>
                </div>
                <div class="col col-lg-3 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername12refer">{{
                      $t("passenger.referBy")
                    }}</label>
                    <input
                      v-model="data.referBy"
                      type="text"
                      class="form-control"
                      id="exampleInputUsername12refer"
                    />
                  </div>
                </div>
                <div class="col col-lg-2 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername13F">{{
                      $t("passenger.amount")
                    }}</label>
                    <input
                      v-model="data.flightAmount"
                      type="number"
                      class="form-control"
                      id="exampleInputUsername13F"
                      required
                    />
                  </div>
                </div>
                <div class="col col-lg-1 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername1Wasl">{{
                      $t("passenger.invoiceNo")
                    }}</label>
                    <input
                      v-model="data.invoiceNo"
                      type="number"
                      class="form-control"
                      id="exampleInputUsername1Wasl"
                      required
                    />
                  </div>
                </div>
                <div class="col col-lg-12 col-12">
                  <div class="form-group">
                    <label for="exampleInputUsername4">{{
                      $t("addUserForm.note")
                    }}</label>
                    <input
                      v-model="data.note"
                      type="text"
                      class="form-control"
                      id="exampleInputUsername4"
                    />
                  </div>
                </div>
              </div>

              <!-- <div class="form-group remove-top-margin">
                <label>{{ $t("currencyType") }}</label>
                <select class="form-control" v-model.number="data.currencyType">
                  <option value="1">{{ $t("paymentTypeOptions.dinnar") }}</option>
                  <option value="2">{{ $t("paymentTypeOptions.dollar") }}</option>
                </select>
              </div> -->

              <button type="submit" class="btn btn-primary me-2" :disabled="isLoading">
                <div
                  class="spinner-border text-light ml-3"
                  v-if="isLoading"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>
                <span>{{ data.id == 0 ? $t("submit") : $t("update") }}</span>
              </button>
              <button
                data-bs-dismiss="modal"
                aria-label="Close"
                id="modalCloseBtn"
                class="btn btn-light"
              >
                {{ $t("close") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-1">
          <div class="card-body">
            <div class="d-lg-flex justify-content-between" style="gap: 5px">
              <div class="d-lg-flex" style="gap: 10px">
                <button
                  @click="resetProperties()"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  type="button"
                  id="editUserBtn"
                  class="btn btn-primary btn-sm rounded-1"
                >
                  {{ $t("addWakel") }}
                </button>
                <button
                  @click="exportToExcel()"
                  class="btn btn-success btn-sm rounded-1"
                  v-if="passengers && passengers.items.length > 0"
                >
                  {{ $t("exportToExcel") }}
                </button>
              </div>
              <div class="col col-lg-4 mb-2">
                <form @submit.prevent="loadPassengers()">
                  <div class="input-group">
                    <input
                      v-model="searchTerm"
                      type="text"
                      class="form-control"
                      :placeholder="$t('searchHere')"
                      :aria-label="$t('search')"
                      aria-describedby="basic-addon1"
                    />
                    <span
                      ><button class="rounded-1 btn btn-primary btn-sm mx-1">
                        {{ $t("search") }}
                      </button></span
                    >
                  </div>
                </form>
              </div>
            </div>
            <div class="table-responsive mt-2">
              <table
                class="table table-striped text-center"
                v-if="passengers && passengers.items.length > 0"
              >
                <thead class="bg-dark text-light">
                  <tr class="text-center">
                    <th>#</th>
                    <th>
                      {{ $t("passenger.name") }}
                    </th>
                    <th>
                      {{ $t("passenger.motherName") }}
                    </th>
                    <th>
                      {{ $t("passenger.passPortNo") }}
                    </th>
                    <th>
                      {{ $t("passenger.phoneNumber") }}
                    </th>
                    <th>
                      {{ $t("passenger.referBy") }}
                    </th>
                    <th>
                      {{ $t("passenger.amount") }}
                    </th>
                    <th>
                      {{ $t("passenger.totalPaid") }}
                    </th>
                    <th>
                      {{ $t("passenger.remaining") }}
                    </th>
                    <th>
                      {{ $t("addUserForm.note") }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(passenger, index) in passengers.items"
                    :key="passenger.id"
                  >
                    <td class="py-1">
                      {{ index + 1 + (pageIndex - 1) * pageSize }}
                    </td>
                    <td>{{ passenger.name }}</td>
                    <td>{{ passenger.motherName }}</td>
                    <td>{{ passenger.passportNo }}</td>
                    <td>{{ passenger.phoneNumber }}</td>
                    <td>{{ passenger.referBy }}</td>
                    <td>
                      {{ passenger.flightAmount.toLocaleString() }}
                      {{ getCurrencyText(passenger.currencyType) }}
                    </td>
                    <td>
                      {{ passenger.totalPaid.toLocaleString() }}
                      {{ getCurrencyText(passenger.currencyType) }}
                    </td>

                    <td>
                      {{ passenger.flightAmount - passenger.totalPaid }}
                      {{ getCurrencyText(passenger.currencyType) }}
                    </td>

                    <td>{{ passenger.note }}</td>

                    <td>
                      <button
                        class="btn btn-primary btn-sm rounded-1 mx-1 my-1"
                        @click="assignData(passenger)"
                      >
                        {{ $t("edit") }}
                      </button>
                      <button
                        class="btn btn-danger btn-sm rounded-1 mx-1 my-1"
                        @click="deleteRow(passenger.id)"
                      >
                        {{ $t("delete") }}
                      </button>

                      <button
                        class="btn btn-warning text-dark btn-sm rounded-1 mx-1 my-1"
                        @click="makePayment(passenger)"
                      >
                        {{ $t("payments") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 v-else class="text-center text-secondary">{{ $t("noData") }}</h4>
            </div>
            <Pagination
              dir="ltr"
              :pageIndex="pageIndex"
              :totalPages="totalPages"
              :onPageChange="handlePageChange"
              :onSizehange="handlePageSize"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get, del, hostname } from "@/api";
import Pagination from "@/components/PaginationComponent.vue";
import $ from "jquery";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "FlightPassengers",
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  components: {
    Pagination,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      searchTerm: null,
      dollarPrice: 0,

      paymentBody: {
        id: 0,
        name: null,
        amount: 0,
        convertedAmount: 0,
        transactionType: "Passenger_Payment",
        currencyType: 2,
        paymentType: 7,
        note: null,
        date: new Date(),
        passengerId: 0,
        flightId: parseInt(this.$route.params.id),
      },

      data: {
        id: 0,
        name: null,
        phoneNumber: null,
        motherName: null,
        passportNo: null,
        passportExpireDate: new Date(),
        enFirstName: null,
        enLastName: null,
        gender: "MALE",
        referBy: null,
        invoiceNo: null,
        dateOfBirth: new Date(),
        flightAmount: 0,
        currencyType: 2,
        note: null,
        flightModelId: parseInt(this.$route.params.id),
      },

      pageIndex: 1,
      pageSize: 30,
      passengers: null,
      passengerTransactions: null,
    };
  },
  computed: {
    totalItems() {
      return this.passengers ? this.passengers.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.passengers.items.slice(startIndex, startIndex + this.pageSize);
    },
    getConvertedAmount() {
      if (this.paymentBody.currencyType == 3) {
        return this.paymentBody.amount / (this.dollarPrice / 100);
      } else if (this.paymentBody.currencyType == 4) {
        return this.paymentBody.amount * (this.dollarPrice / 100);
      }
      return 0;
    },
  },
  methods: {
    async exportToExcel() {
      this.$loading(true);
      const URL = `/api/FlightPassengers/generateExcelReportPassengers?flightId=${this.$route.params.id}`;
      await get(URL);
      const fileURL = `${hostname}/files/report.xlsx?unique=true`;
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "reports.xlsx");
      document.body.appendChild(link);
      link.click();
      this.$loading(false);
    },
    async loadPassengerTransaction(passengerId) {
      this.$loading(true);
      const URL = `/api/flightPassengers/GetPassengerTransactions?userId=${passengerId}`;
      this.passengerTransactions = await get(URL);
      this.$loading(false);
    },
    async getDollarPrice() {
      const URL = "/Api/Settings";
      this.dollarPrice = await get(URL);
    },

    async makeTransaction() {
      this.$loading(true);
      this.isLoading = true;

      this.paymentBody.paymentType = parseInt(this.paymentBody.paymentType);
      this.paymentBody.currencyType = parseInt(this.paymentBody.currencyType);

      this.paymentBody.convertedAmount = this.getConvertedAmount;
      const URL = `/api/userTransactions`;
      await post(URL, this.paymentBody);
      this.resetPaymentBody();
      await this.loadPassengers();
      this.$loading(false);
      this.isLoading = false;

      $("#paymentModalCloseBtn").click();
    },
    assignDataTransaction(transaction) {
      this.paymentBody = {
        id: transaction.id,
        name: this.paymentBody.name,
        amount: transaction.amount,
        convertedAmount: transaction.convertedAmount,
        transactionType: "Passenger_Payment",
        currencyType: 2,
        paymentType: 7,
        note: transaction.note,
        date: this.formatDatee(transaction.date),
        passengerId: transaction.passengerId,
        flightId: parseInt(this.$route.params.id),
      };
    },
    deleteRowTransaction(id) {
      console.log(id);
    },
    resetPaymentBody() {
      this.paymentBody = {
        id: 0,
        name: null,
        amount: 0,
        convertedAmount: 0,
        transactionType: "Passenger_Payment",
        currencyType: 2,
        paymentType: 7,
        note: null,
        date: new Date(),
        passengerId: 0,
        flightId: parseInt(this.$route.params.id),
      };
    },
    async makePayment(passenger) {
      this.resetPaymentBody();
      this.paymentBody.name = passenger.name;
      this.paymentBody.passengerId = passenger.id;
      this.paymentBody.currencyType = passenger.currencyType;
      await this.loadPassengerTransaction(passenger.id);
      await this.openModal("exampleModalMakePayment");
    },
    assignData(passedFlight) {
      this.data = {
        id: passedFlight.id,
        name: passedFlight.name,
        phoneNumber: passedFlight.phoneNumber,
        motherName: passedFlight.motherName,
        passportNo: passedFlight.passportNo,
        passportExpireDate: this.formatDateToDDMMYYYY(new Date(passedFlight.passportExpireDate)),
        enFirstName: passedFlight.enFirstName,
        enLastName: passedFlight.enLastName,
        gender: passedFlight.gender,
        referBy: passedFlight.referBy,
        invoiceNo: passedFlight.invoiceNo,
        dateOfBirth: this.formatDateToDDMMYYYY(new Date(passedFlight.dateOfBirth)),
        flightAmount: passedFlight.flightAmount,
        currencyType: passedFlight.currencyType,
        note: passedFlight.note,
        flightModelId: parseInt(this.$route.params.id),

        
      };

      this.openModal("exampleModal");
    },

    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadPassengers();
    },
    async handlePageSize(newSize) {
      this.pageSize = newSize;
      await this.loadPassengers();
    },
    async postPassenger() {
      this.data.passportExpireDate = this.convertToYYYYMMDD(this.data.passportExpireDate);
      this.data.dateOfBirth = this.convertToYYYYMMDD(this.data.dateOfBirth);
      this.$loading(true);
      this.isLoading = true;
      const URL = `/api/flightPassengers`;
      await post(URL, this.data);
      this.$loading(false);
      this.isLoading = false;
      await this.loadPassengers();
      $("#modalCloseBtn").click();
    },
    async loadPassengers() {
      this.$loading(true);
      const URL = `/api/flightPassengers?flightId=${this.$route.params.id}&page=${this.pageIndex}&size=${this.pageSize}&search=${this.searchTerm}`;
      this.passengers = await get(URL);
      this.$loading(false);
    },
    deleteRow(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/flightPassengers?entityId=${id}`;
          await del(URL, false);
          await this.loadPassengers();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    resetProperties() {
      this.data = {
        id: 0,
        name: null,
        phoneNumber: null,
        motherName: null,
        passportNo: null,
        passportExpireDate: new Date(),
        enFirstName: null,
        enLastName: null,
        gender: "MALE",
        referBy: null,
        invoiceNo: null,
        dateOfBirth: new Date(),
        flightAmount: 0,
        currencyType: 2,
        note: null,
        flightModelId: parseInt(this.$route.params.id),
      };
    },
  },
  async mounted() {
    await this.loadPassengers();
    await this.getDollarPrice();
  },
};
</script>
