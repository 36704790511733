<style scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
}
table td {
  padding: 5px !important;
  margin: 0 !important;
}
</style>

<template>
  <div>
    <div class="row justify-content-between">
      <div class="col col-lg-4">
        <Modal
          class="mb-2"
          :title="isUpdating ? modalUpdateTitle : modalCreateTitle"
          :fields="tableConfig.fields"
          :url="tableConfig.url"
          :is-updating="isUpdating"
          :initial-values="updateValues"
          @data-updated="handleDataUpdated"
        />
      </div>
      <div class="col col-lg-3 col-12">
        <input
          type="text"
          class="form-control my-1"
          :placeholder="$t('searchHere')"
          v-model="searchText"
          @keydown.enter="doFilter"
        />
      </div>
    </div>

    <div class="table-responsive">
      <table
        class="table table-hover my-0 table-striped"
        v-if="dataRows && dataRows.items"
      >
        <thead style="background-color: #222e3c">
          <tr class="text-light">
            <th>#</th>
            <th v-for="field in tableFields" :key="field.name">{{ field.title }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in dataRows.items" :key="row.id">
            <td>{{ index + 1 + (pageIndex - 1) * pageSize }}</td>
            <td v-for="field in tableFields" :key="field.name" :class="field.type">
              {{ row[field.name] }}
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm rounded-1 mx-1 my-1"
                @click="editRow(row)"
              >
                {{ $t("edit") }}
              </button>
              <button
                class="btn btn-danger btn-sm rounded-1 mx-1 my-1"
                @click="deleteRow(row)"
              >
                {{ $t("actiosns.delete") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        dir="ltr"
        :pageIndex="pageIndex"
        :totalPages="totalPages"
        :onPageChange="handlePageChange"
        :onSizehange="handlePageSize"
      />
    </div>
  </div>
</template>

<script>
import { get, del } from "@/api";
import { mapState } from "vuex";
import Modal from "@/components/CreateModal.vue";
import Pagination from "@/components/PaginationComponent.vue";
export default {
  name: "DataTable",
  components: {
    Modal,
    Pagination,
  },
  props: {
    tableConfig: {
      type: Object,
      required: true,
    },
    modalCreateTitle: {
      type: String,
      required: true,
    },
    modalUpdateTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataRows: [],
      isUpdating: false,
      updateValues: null,

      pageIndex: 1,
      pageSize: 25,
      searchText: null,

      isExpense: this.$route.params.isExpense == 1,
    };
  },
  computed: {
    tableFields() {
      return this.tableConfig.fields
        .filter((field) => field.visible)
        .map((field) => ({
          title: field.title,
          name: field.name,
          type: field.type,
        }));
    },
    totalItems() {
      return this.dataRows.items ? this.dataRows.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.dataRows.items.slice(startIndex, startIndex + this.pageSize);
    },
    ...mapState({
      refreshData: (state) => state.refreshData,
    }),
  },
  methods: {
    handlePageChange(newPage) {
      this.pageIndex = newPage;
      this.fetchData();
    },
    handlePageSize(newSize) {
      this.pageSize = newSize;
      this.fetchData();
    },
    handleDataUpdated() {
      this.updateValues = null;
      this.isUpdating = false;
    },
    async fetchData() {
      this.$loading(true);
      const URL = `${this.tableConfig.url}?isExpense=${this.isExpense}&page=${this.pageIndex}&size=${this.pageSize}&search=${this.searchText}`;
      try {
        this.dataRows = await get(URL);
      } catch (error) {
        console.log(error);
      } finally {
        this.$loading(false);
      }
    },
    editRow(row) {
      this.isUpdating = true;
      this.updateValues = row;
      // eslint-disable-next-line no-undef
      var myModal = new bootstrap.Modal(document.getElementById("exampleModal"), {
        keyboard: false,
      });
      myModal.show();
    },
    deleteRow(row) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `${this.tableConfig.url}?entityId=${row.id}`;
          await del(URL, true);
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    async doFilter() {
      if (event.keyCode === 13) {
        await this.fetchData();
      }
    },
  },
  watch: {
    refreshData(newValue) {
      if (newValue) {
        this.fetchData();
        this.$store.commit("SET_REFRESH_DATA", false);
      }
    },
    async $route() {
      this.isExpense = this.$route.params.isExpense == 1;
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>
