<style scoped>
.table td,
th {
    padding: 10px;
    text-align: center;
}
</style>

<template>
    <div>
        <div class="col col-lg-4 col-md-4 col-12 mt-2 mb-2" v-if="report">
            <div class="card rounded-2 shadow-sm" style="background-color: #c44d56;">
                <div class="card-body text-light">
                    <h6 class="fw-bold">
                        {{ $t("customersTable.amount") }}
                    </h6>
                    <div class="row">
                        <div class="col-10" style="font-size: 30px;">
                            {{ monthTotalDebt.toLocaleString('en-US') }} <span>{{ $t("currency.main") }}</span>
                        </div>
                        <div class="col-2">
                            <i class="mdi mdi-cash-multiple" style="font-size: 30px;"></i>
                        </div>
                    </div>
                    <small>{{ report[0].month }} - {{ report[0].year }}</small>
                </div>
            </div>
        </div>
        <div class="card rounded-2">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped table-sm">
                        <thead class="bg-dark text-light">
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    {{ $t("customersTable.name") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.phone") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.month") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.amount") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.paymentType") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.renewDate") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.expireDate") }}
                                </th>
                                <th>
                                    {{ $t("customersTable.paidDate") }}
                                </th>

                                <th>
                                    {{ $t("customersTable.view") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(transaction, index) in report" :key="transaction.id">
                                <td class="py-1">
                                    {{ (index + 1) }}
                                </td>
                                <td>
                                    {{ transaction.user.fullName }}
                                </td>
                                <td>
                                    {{ transaction.user.phoneNumber }}
                                </td>
                                <td>
                                    {{ transaction.month }} - {{ transaction.year }}
                                </td>
                                <td>
                                    {{ transaction.amount.toLocaleString('en-US') }} <span>{{ $t("currency.main") }}
                                    </span>
                                </td>
                                <td>
                                    <div :class="getPaymentTextClass(transaction.paymentType)" class="badge me-3">
                                        {{ getPaymentText(transaction.paymentType) }}</div>
                                </td>
                                <td>
                                    {{ cleanDate(transaction.renewDate) }}
                                </td>
                                <td>
                                    {{ cleanDate(transaction.expireDate) }}
                                </td>
                                <td>
                                    {{ cleanDate(transaction.paidDate) }}
                                </td>

                                <td class="text-center">
                                    <router-link :to="`/Customer/Profile/${transaction.userId}`"> <i
                                            class="mdi mdi-eye big-icon"></i></router-link>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <!-- <h4 v-else class="text-center text-secondary">{{ $t("noData") }}</h4> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SingleMonthReport',
    mounted()
    {
        if (!this.checkLogin())
        {
            this.$router.push("/login");
            return
        }
        if (!this.checkRootAdmin())
        {
            this.$router.push("/login");
            return
        }

        this.getSingleMonthReport();
    },
    data()
    {
        return {
            report: null,
            monthTotalDebt: 0
        }
    },
    methods: {
        getSingleMonthReport()
        {
            this.$loading(true);
            this.$Axios.get(`${this.$hostname}/api/userTransactions/generateSingleMonthReportList?monthId=${parseInt(this.$route.params.month)}&yearId=${parseInt(this.$route.params.year)}`, this.config())
                .then((res) =>
                {
                    this.report = res.data;
                    if (this.report)
                    {
                        this.report.forEach(element =>
                        {
                            this.monthTotalDebt += element.amount
                        });
                    }
                    this.$loading(false);
                })
                .catch((err) =>
                {
                    this.$loading(false);
                    this.$toast.open({
                        message: err.toString(),
                        type: 'error',
                    });
                });
        },
    }

}
</script>