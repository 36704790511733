<style scoped>
.remove-top-margin {
  margin-top: -15px;
}

.dataCol {
  border: 1px solid black !important;
}

.spinner-border {
  width: 15px !important;
  height: 15px !important;
  padding: 0%;
}

@media print {
  .monthCol {
    background-color: rgba(0, 0, 0, 0.16) !important;
    print-color-adjust: exact;
  }

  .phoneNumbers {
    background-color: black;
    color: white;
    print-color-adjust: exact;
    padding: 5px;
  }

  .titleDate {
    background-color: rgba(0, 0, 0, 0.23) !important;
    print-color-adjust: exact;
  }
}
</style>

<template>
  <div>
    <!-- Add Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <form class="my-4 forms-sample" @submit.prevent="postTransaction()">
              <div class="form-group remove-top-margin">
                <label for="exampleInput3">{{ $t("addUserTransaction.amount") }}</label>
                <input
                  class="form-control"
                  id="exampleInput3"
                  type="number"
                  min="0"
                  v-model.number="customerTransactionBody.amount"
                />
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput4">{{
                  $t("addUserTransaction.currencyType")
                }}</label>
                <select
                  v-model="customerTransactionBody.currencyType"
                  required
                  class="form-control"
                  id="exampleInput4"
                >
                  <option value="1">{{ $t("paymentTypeOptions.dinnar") }}</option>
                  <option value="2">{{ $t("paymentTypeOptions.dollar") }}</option>
                  <!-- <option value="3" v-if="isWakel || isZabwn">
                    {{ $t("paymentTypeOptions.convertToDollar") }}
                  </option>
                  <option value="4" v-if="isWakel || isZabwn">
                    {{ $t("paymentTypeOptions.convertToDinnar") }}
                  </option> -->
                </select>
              </div>
              <div
                class="exchangeRates"
                v-if="
                  customerTransactionBody.currencyType == 3 ||
                  customerTransactionBody.currencyType == 4
                "
              >
                <div class="row">
                  <div class="col-6 form-group remove-top-margin">
                    <label>{{ $t("addUserTransaction.exchangeRate") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      :value="dollarPrice / 100"
                    />
                  </div>
                  <div class="col-6 form-group remove-top-margin">
                    <label>{{ $t("addUserTransaction.convertedAmount") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      :value="getConvertedAmount"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group remove-top-margin">
                <label for="exampleInput4">{{
                  $t("addUserTransaction.paymentType")
                }}</label>
                <select
                  v-model="customerTransactionBody.paymentType"
                  required
                  class="form-control"
                  id="exampleInput4"
                >
                  <option value="2" v-if="isWakel || isZabwn">
                    {{
                      isWakel
                        ? $t("filters.irsalRaset")
                        : isZabwn
                        ? $t("filters.sarf")
                        : $t("filters.hawala")
                    }}
                  </option>
                  <option value="1">
                    {{ isSairafa ? $t("filters.doHawala") : $t("filters.qabz") }}
                  </option>
                  <option value="3" v-if="isSairafa">
                    {{
                      isWakel
                        ? $t("filters.return")
                        : isZabwn
                        ? $t("filters.sahbbb")
                        : $t("filters.qabz")
                    }}
                  </option>
                  <option value="4" v-if="isSairafa">
                    {{ $t("filters.dafhawala") }}
                  </option>
                </select>
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput6">{{ $t("addUserTransaction.date") }}</label>
                <input
                  id="exampleInput6"
                  class="form-control"
                  type="date"
                  v-model="customerTransactionBody.date"
                />
              </div>
              <div
                class="form-group remove-top-margin"
                v-if="isZabwn && customerTransactionBody.paymentType == 2"
              >
                <label for="exampleInput6">{{ $t("addUserTransaction.qabzDate") }}</label>
                <input
                  id="exampleInput6"
                  class="form-control"
                  type="date"
                  v-model="customerTransactionBody.returnOn"
                />
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput7">{{ $t("addUserTransaction.note") }}</label>
                <input
                  id="exampleInput7"
                  class="form-control"
                  type="text"
                  v-model="customerTransactionBody.note"
                />
              </div>
              <button type="submit" class="btn btn-primary me-2" :disabled="isLoading">
                <div
                  class="spinner-border text-light ml-3"
                  v-if="isLoading"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>

                <span v-else>
                  {{
                    customerTransactionBody.id ? $t("dropdownOptions.edit") : $t("submit")
                  }}
                </span>
              </button>
              <button
                data-bs-dismiss="modal"
                aria-label="Close"
                class="btn btn-light modalCloseBtn"
              >
                {{ $t("close") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <CreateAttachmentModal
      type="2"
      :entity-id="selectedData"
      :onAttachmentCreation="handleAfterAddAttachment"
    />
    <div class="home-tab">
      <div class="border-bottom" style="font-family: Arabic">
        <div class="row">
          <div class="col col-lg-9 col-12">
            <div class="row">
              <div class="col col-lg-3 col-12">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text text-dark" id="basic-addon1">{{
                      $t("filters.paymentType")
                    }}</span>
                  </div>
                  <select
                    class="form-control text-center rtl-bordered text-dark"
                    v-model="selectedPaymentType"
                    @change="applyFilter()"
                  >
                    <option value="0">{{ $t("filters.all") }}</option>
                    <option value="2" v-if="isWakel || isZabwn">
                      {{
                        isWakel
                          ? $t("filters.irsalRaset")
                          : isZabwn
                          ? $t("filters.sarf")
                          : $t("filters.hawala")
                      }}
                    </option>
                    <option value="1">
                      {{ isSairafa ? $t("filters.doHawala") : $t("filters.qabz") }}
                    </option>
                    <option value="4" v-if="isSairafa">
                      {{ $t("filters.dafhawala") }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col col-lg-3 col-12">
                <div class="input-group mb-3 mx-lg-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text text-dark" id="basic-addon1">{{
                      $t("filters.currencyType")
                    }}</span>
                  </div>
                  <select
                    class="form-control text-center rtl-bordered text-dark"
                    v-model="selectedCurrencyType"
                    @change="applyFilter()"
                  >
                    <option value="0">{{ $t("filters.all") }}</option>
                    <option value="1">{{ $t("filters.dinnar") }}</option>
                    <option value="2">{{ $t("filters.dollar") }}</option>
                  </select>
                </div>
              </div>
              <div class="col col-lg-3 col-12">
                <div class="input-group mb-3 mx-lg-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text text-dark" id="basic-addon1">{{
                      $t("filters.dateFrom")
                    }}</span>
                  </div>
                  <input
                    type="date"
                    class="form-control rtl-bordered"
                    v-model="selectedDateFrom"
                    @change="applyFilter"
                  />
                </div>
              </div>
              <div class="col col-lg-3 col-12">
                <div class="input-group mb-3 mx-lg-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text text-dark" id="basic-addon1">{{
                      $t("filters.dateTo")
                    }}</span>
                  </div>
                  <input
                    type="date"
                    class="form-control rtl-bordered"
                    v-model="selectedDateTo"
                    @change="applyFilter"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col col-lg-3 col-12"
            v-if="transactions && transactions.items.length > 0"
          >
            <div class="btn-wrapper">
              <div class="actions-cont justify-content-start">
                <!-- <a
                  role="button"
                  class="btn btn-primary text-white"
                  @click="generateToExcel"
                  ><i class="icon-download"></i>
                  {{ $t("exportOptions.exportToExcel") }}</a
                > -->
                <button class="btn btn-primary text-white" @click="doPrint()">
                  {{ $t("exportOptions.print") }}
                </button>
                <button
                  id="print-btn"
                  class="btn btn-primary text-white d-none"
                  v-print="'#printMeTransactions'"
                >
                 test
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <InvoicePrint
      v-if="user && selectedPrintTransaction"
      :transaction="selectedPrintTransaction"
      :userInfo="user"
    />

    <div id="printMeTransactions" dir="rtl">
      <div class="row mt-3" v-if="user">
        <div class="col col-lg-4 col-md-4 col-12 mt-2">
          <div
            class="card rounded-2 shadow-sm"
            style="height: 100%"
            :class="$store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode'"
          >
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h6 class="fw-bold">
                  {{
                    isWakel
                      ? $t("customersTable.name")
                      : isZabwn
                      ? $t("customersTable.zabwn")
                      : $t("customersTable.sairafa")
                  }}:
                </h6>
                <h6 class="mb-2 text-secondary">
                  {{ user.fullName }}
                </h6>
              </div>
              <div class="d-flex justify-content-between mt-3">
                <h6 class="fw-bold">{{ $t("customersTable.phone") }}:</h6>
                <h6 class="text-secondary">
                  {{ user.phoneNumber }}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-lg-4 col-md-4 col-12 mt-2">
          <div
            class="card rounded-2 shadow-sm"
            :class="
              $store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode-profile'
            "
          >
            <div class="card-body">
              <h6 class="fw-bold">
                {{ $t("customersTable.amount") }}
              </h6>
              <div class="row">
                <div class="col-10" style="font-size: 30px">
                  {{
                    (user.totalDebtDinnar - user.totalPaidDinnar).toLocaleString("en-US")
                  }}
                  <span>{{ $t("currency.main") }}</span>
                </div>
                <div class="col-2">
                  <i class="mdi mdi-cash-multiple" style="font-size: 30px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-lg-4 col-md-4 col-12 mt-2">
          <div
            class="card rounded-2 shadow-sm"
            :class="
              $store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode-profile'
            "
          >
            <div class="card-body">
              <h6 class="fw-bold">
                {{ $t("customersTable.amountDollar") }}
              </h6>
              <div class="row">
                <div class="col-10" style="font-size: 30px">
                  {{
                    (user.totalDebtDollar - user.totalPaidDollar).toLocaleString("en-US")
                  }}
                  <span>{{ $t("currency.secondary") }}</span>
                </div>
                <div class="col-2">
                  <i class="mdi mdi-cash-multiple" style="font-size: 30px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card rounded-2 mt-3 shadow-sm">
        <div class="card-body">
          <button
            @click="resetFields"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            type="button"
            id="add-transaction-btn"
            class="rounded-1 btn btn-primary btn-sm btn-icon-text mb-2"
          >
            {{ $t("addTransaction") }}
          </button>
          <div class="table-responsive">
            <table
              class="table table-sm table-striped table-hover text-center"
              v-if="transactions && transactions.items.length > 0"
            >
              <thead class="bg-dark text-light">
                <tr>
                  <th>#</th>
                  <th>
                    {{ $t("addUserTransaction.amount") }}
                  </th>
                  <th>
                    {{ $t("addUserTransaction.paymentType") }}
                  </th>

                  <th>
                    {{ $t("addUserTransaction.date") }}
                  </th>
                  <th v-if="isZabwn">
                    {{ $t("addUserTransaction.qabzDate") }}
                  </th>
                  <th>
                    {{ $t("addUserTransaction.note") }}
                  </th>
                  <th class="hide-print">
                    {{ $t("dropdownOptions.edit") }}
                  </th>
                  <th class="hide-print">
                    {{ $t("dropdownOptions.print") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transaction, index) in transactions.items"
                  :key="transaction.id"
                >
                  <td class="py-1">
                    {{ ++index }}
                  </td>
                  <td>
                    {{ transaction.amount.toLocaleString("en-US") }}
                    <span>{{ getCurrencyText(transaction.currencyType) }} </span>
                    <span
                      class="d-block my-2"
                      v-if="
                        transaction.currencyType == 3 || transaction.currencyType == 4
                      "
                      >{{ $t("addUserTransaction.convertedAmount") }}:
                      {{ transaction.convertedAmount.toLocaleString() }}</span
                    >
                  </td>
                  <td>
                    <div
                      :class="getPaymentTextClass(transaction.paymentType)"
                      class="badge me-3"
                    >
                      {{
                        isWakel
                          ? getPaymentTextWakel(transaction.paymentType)
                          : isZabwn
                          ? getPaymentTextZabwn(transaction.paymentType)
                          : getPaymentTextSairafa(transaction.paymentType)
                      }}
                    </div>
                  </td>
                  <td>
                    {{ cleanDate(transaction.date) }}
                  </td>
                  <td v-if="isZabwn">{{ cleanDate(transaction.returnOn) }}</td>

                  <td>
                    {{ transaction.note }}
                  </td>
                  <td class="hide-print">
                    <a
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      @click="assignTransactionBody(transaction)"
                    >
                      <i class="mdi mdi-pencil big-icon"></i
                    ></a>
                  </td>
                  <td class="hide-print">
                    <a
                      role="button"
                      @click="printSingle(transaction)"
                      v-print="'#printMe'"
                    >
                      <i class="icon-printer big-icon"></i
                    ></a>
                    <button
                    v-if="!transaction.attachmentLink"
                      class="btn btn-warning text-dark btn-sm rounded-1 mx-1 my-1"
                      @click="openAttachmentModel(transaction)"
                    >
                      {{ $t("newAttachment") }}
                    </button>
                    <div v-else>
                      <a
                      :href="$hostname+transaction.attachmentLink.replaceAll('\\', '/')"
                        class="btn btn-outline-primary rounded-1 btn-sm mx-1 my-1"
                      
                      >
                        {{ $t("ViewAttachment") }}
                    </a>
                      <button
                        class="btn btn-outline-danger rounded-1 btn-sm mx-1 my-1"
                        @click="deleteAttachment(transaction.attachmentId)"
                      >
                        {{ $t("DeleteAttachment") }}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 v-else class="text-center text-secondary">{{ $t("noTransaction") }}</h4>
          </div>
          <Pagination
            id="paginations"
            dir="ltr"
            :pageIndex="pageIndex"
            :totalPages="totalPages"
            :onPageChange="handlePageChange"
            :onSizehange="handlePageSize"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Pagination from "@/components/PaginationComponent.vue";
import InvoicePrint from "@/components/Prints/InvoicePrint.vue";
import { get, post, del } from "@/api";
import { mapState } from "vuex";
import CreateAttachmentModal from "@/components/CreateAttachmentModal.vue";

export default {
  name: "agentProfile",
  components: {
    Pagination,
    InvoicePrint,
    CreateAttachmentModal
  },
  data() {
    return {
      dollarPrice: 0,
      selectedPaymentType: 0,
      selectedCurrencyType: 0,
      selectedDateFrom: null,
      selectedDateTo: null,
      customerTransactionBody: {
        id: 0,
        userId: parseInt(this.$route.params.id),
        amount: 0,
        convertedAmount: 0,
        transactionType: this.getTransacitonType(this.$route.params.type),
        currencyType: 1,
        paymentType: 0,
        note: null,
        date: new Date(),
        forceReturn: false,
        returnOn: null,
      },

      errorMessage: null,
      transactions: null,
      user: null,

      isLoading: false,

      pageSize: 10,
      pageIndex: 1,

      selectedPrintTransaction: null,

      isWakel: this.$route.params.type == "3",
      isZabwn: this.$route.params.type == "5",
      isSairafa: this.$route.params.type == "6",
      selectedData: "0",
    };
  },
  computed: {
    totalItems() {
      return this.transactions ? this.transactions.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.transactions.items.slice(startIndex, startIndex + this.pageSize);
    },
    getConvertedAmount() {
      if (this.customerTransactionBody.currencyType == 3) {
        return this.customerTransactionBody.amount / (this.dollarPrice / 100);
      } else if (this.customerTransactionBody.currencyType == 4) {
        return this.customerTransactionBody.amount * (this.dollarPrice / 100);
      }
      return 0;
    },
    ...mapState(["isDarkMode"]),
  },
  methods: {
    async handleAfterAddAttachment() {
      await this.getTransactions();
    },
    deleteAttachment(id) {
      this.$confirm(this.$t("messages.deleteConfirmation"), "Question", "question")
        .then(async () => {
          this.$loading(true);
          const URL = `/api/uploader?id=${id}`;
          await del(URL);
          await this.getTransactions();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    openAttachmentModel(data) {
      this.selectedData = data.id.toString();
      this.openModal("CreateAttachmentModal");
    },

    doPrint() {
      this.pageSize = 10000;
      this.transactions = null;
      this.getTransactions();
      setTimeout(() => {
        $("#print-btn").click();
      }, 500);
    },
    async getDollarPrice() {
      const URL = "/Api/Settings";
      this.dollarPrice = await get(URL);
    },
    getTransacitonType(transactionType) {
      if (transactionType == 3) return "agent";
      if (transactionType == 5) return "zabwn";
      if (transactionType == 6) return "sairafa";
    },
    handlePageChange(newPage) {
      this.pageIndex = newPage;
      this.getTransactions();
    },
    handlePageSize(newSize) {
      this.pageSize = newSize;
      this.getTransactions();
    },
    resetFields() {
      this.customerTransactionBody = {
        id: 0,
        userId: parseInt(this.$route.params.id),
        amount: 0,
        convertedAmount: 0,
        transactionType: this.getTransacitonType(this.$route.params.type),
        currencyType: 1,
        paymentType: 0,
        note: null,
        date: new Date(),
        forceReturn: false,
        returnOn: null,
      };
    },
    printSingle(transaction) {
      this.selectedPrintTransaction = transaction;
    },
    assignTransactionBody(transaction) {
      this.customerTransactionBody = {
        id: transaction.id,
        userId: parseInt(this.$route.params.id),
        amount: transaction.amount,
        convertedAmount: transaction.convertedAmount,
        transactionType: transaction.transactionType,
        currencyType: transaction.currencyType,
        paymentType: transaction.paymentType,
        note: transaction.note,
        date: this.formatDate(transaction.date),
        forceReturn: transaction.forceReturn,
        returnOn: this.formatDate(transaction.returnOn),
      };
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    async postTransaction() {
      this.$loading(true);
      this.isLoading = true;
      if (this.customerTransactionBody.returnOn != null) {
        this.customerTransactionBody.forceReturn = true;
      } else {
        this.customerTransactionBody.forceReturn = false;
      }

      this.errorMessage = null;
      this.customerTransactionBody.paymentType = parseInt(
        this.customerTransactionBody.paymentType
      );
      this.customerTransactionBody.currencyType = parseInt(
        this.customerTransactionBody.currencyType
      );
      this.customerTransactionBody.convertedAmount = this.getConvertedAmount;
      const URL = `/api/userTransactions`;
      await post(URL, this.customerTransactionBody);
      this.resetFields();
      $(".modalCloseBtn").trigger("click");
      await this.getTheUser();
      await this.getTransactions();
      this.$loading(false);
      this.isLoading = false;
    },

    async getTransactions() {
      this.$loading(true);
      const URL = `/api/userTransactions/specific?userId=${this.$route.params.id}&page=${this.pageIndex}&size=${this.pageSize}&paymentType=${this.selectedPaymentType}&currencyType=${this.selectedCurrencyType}&from=${this.selectedDateFrom}&to=${this.selectedDateTo}`;
      this.transactions = await get(URL);
      this.$loading(false);
    },
    async getTheUser() {
      this.$loading(true);
      const URL = `/api/users/specificUser?userId=${this.$route.params.id}`;
      this.user = await get(URL);
      this.$loading(false);
    },
    async applyFilter() {
      await this.getTransactions();
    },
    async generateToExcel() {
      this.$loading(true);
      const URL = `/api/UserTransactions/generateExcelReport?userId=${this.$route.params.id}&page=${this.pageIndex}&size=${this.pageSize}&paymentType=${this.selectedPaymentType}&currencyType=${this.selectedCurrencyType}&from=${this.selectedDateFrom}&to=${this.selectedDateTo}`;
      await get(URL);
      const fileURL = `${this.$hostname}/files/report.xlsx?unique=true`;
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "reports.xlsx");
      document.body.appendChild(link);
      link.click();
      this.$loading(false);
    },
  },

  async mounted() {
    await this.getTheUser();
    await this.getTransactions();
    await this.getDollarPrice();
  },
};
</script>

<style scoped>
@media print {
  .hide-print {
    display: none !important;
  }

  #add-transaction-btn {
    display: none;
  }

  #paginations {
    display: none;
  }
}
</style>
