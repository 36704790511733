<style>
.big-icon {
  font-size: 22px;
}

.big-icon:hover,
.dropdownOption:hover,
.arrow-btn:hover {
  cursor: pointer;
}

.dropdownOption {
  font-size: 12px;
}
</style>

<template>
  <div id="app">
    <div
      class="container-scroller"
      v-if="$route.name != 'login' && $route.name != 'ClientDashboard'"
    >
      <Navbar />
      <div class="container-fluid page-body-wrapper rtl">
        <RightSidebar />
        <Sidebar />
        <div class="main-panel">
          <div
            class="content-wrapper"
            :class="isDarkMode ? 'dark-mode-main' : 'light-mode-main'"
          >
            <div class="row mt-4">
              <div class="col-sm-12">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="$route.name == 'ClientDashboard'">
      <router-view />
    </div>

    <div class="container-scroller rtl" v-if="$route.name == 'login'">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth px-0">
          <div class="row w-100 mx-0">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                <div
                  class="brand-logo d-flex justify-content-center"
                  style="margin-top: -30px; margin-bottom: -5px"
                >
                  <img
                    src="./assets/logo.png"
                    alt="logo"
                    style="width: 100px"
                    class="rounded"
                  />
                </div>
                <h4>{{ $t("hello") }}</h4>
                <h6 class="fw-light text-secondary">{{ $t("signInToContinue") }}</h6>
                <form class="pt-3" @submit.prevent="authenticate">
                  <div class="form-group">
                    <input
                      v-model="loginBody.username"
                      required
                      type="text"
                      class="form-control form-control-lg"
                      :disabled="usernameInserted"
                      id="exampleInputEmail1"
                      :placeholder="$t('username')"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      v-model="loginBody.password"
                      required
                      type="password"
                      class="form-control form-control-lg"
                      id="exampleInputPassword1"
                      :placeholder="$t('password')"
                    />
                  </div>
                  <div class="mt-3">
                    <button
                      type="submit"
                      class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn w-100"
                    >
                      {{ $t("signIn") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Navbar from "./components/NavBar.vue";
import Sidebar from "./components/SideBar.vue";
import RightSidebar from "./components/RightSideBar.vue";
export default {
  /* eslint-disable */
  name: "app",
  components: {
    Navbar,
    Sidebar,
    RightSidebar,
  },
  computed: {
    ...mapState(["isDarkMode"]),
  },
  data() {
    return {
      loginBody: {
        username: null,
        password: null,
      },
      message: null,
      usernameInserted: false,

      totalExpiresToday: 0,
    };
  },

  methods: {
    authenticate() {
      this.$loading(true);
      this.$Axios
        .post(
          `${this.$hostname}/api/users/authenticate?username=${this.loginBody.username}&password=${this.loginBody.password}`
        )
        .then((res) => {
          if (res.status == 200) {
            if (!res.data.isActive) {
              this.$toast.open({
                message: this.$t("messages.userNotActive"),
                type: "error",
              });
              this.$loading(false);
              return;
            }
            this.$session.start();
            this.$session.set("id", res.data.id);
            this.$session.set("username", res.data.username);
            this.$session.set("fullName", res.data.fullName);
            this.$session.set("phoneNumber", res.data.phoneNumber);
            this.$session.set("role", res.data.role);
            this.$session.set("token", res.data.token);
            this.$session.set("tenantName", res.data.tenantName);
            this.$store.commit("setLoggedUser", {
              id: res.data.id,
              username: res.data.username,
              role: res.data.role,
              token: res.data.token,
            });
            this.$toast.open({
              message: this.$t("messages.loginedSuccessfully"),
              type: "success",
            });
            if (res.data.role == "RootAdmin" || res.data.role == "Admin" || res.data.role == "Employee") {
              this.$router.push("/");
            } else {
              this.$router.push("/Client/Dashboard");
            }
          } else {
            this.$toast.open({
              message: this.$t("messages.credentialError"),
              type: "error",
            });
          }
          this.$loading(false);
        })
        .catch((err) => {
          this.message = this.$t("messages.internetError");
          if (err.response) {
            this.message = err.response.data.toString();
          }
          this.$toast.open({
            message: this.message,
            type: "error",
          });
          this.$loading(false);
        });
    },
  },
  mounted() {
    if (this.$route.name == "login") {
      setTimeout(() => {
        if (parseInt(new URLSearchParams(window.location.search).get("username"))) {
          this.loginBody.username = parseInt(
            new URLSearchParams(window.location.search).get("username")
          );
          this.usernameInserted = true;
        }
      }, 500);
      return;
    }
  },
};
</script>
