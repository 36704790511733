import axios from 'axios'
import Vue from 'vue'
import VueToast from 'vue-toast-notification';
import i18n from '../i18n'
import store from '../store'
import 'vue-toast-notification/dist/theme-default.css'
import mixin from '../generalMixin';

Vue.use(VueToast)
//ss
//export const hostname = 'https://localhost:44355'
export const hostname = 'https://www.sabelzamzam.com'

const http = axios.create({
  baseURL: hostname
})

export const get = async (path) => {
  try {
    const response = await http.get(path, mixin.methods.config())
    return response.data
  } catch (error) {
    let message = null;
    if (error.response) {
      message = error.response.data;
    } else {
      message = i18n.t("messages.error");
    }
    console.log(message);
    // Vue.$toast.open({
    //   message: message ,
    //   type: 'error'
    // })
    return null
  }
}

export const post = async (path, data = {}, refreshData = false) => {
  try {
    const response = await http.post(path, data, mixin.methods.config())
    Vue.$toast.open({
      message: i18n.t("messages.dataAdded"),
      type: 'success'
    })
    if (refreshData) {
      store.dispatch('setRefreshData', true);
      console.log("set refresh data to true");
    }

    return response.data
  } catch (error) {
    let message = null;
    if (error.response) {
      message = error.response.data;
    } else {
      message = i18n.t("messages.error");
    }
    Vue.$toast.open({
      message: message,
      type: 'error'
    })
    console.error(error)
    return null
  }
}

export const put = async (path, data = {}, refreshData = false) => {
  try {
    const response = await http.put(path, data, mixin.methods.config())
    if (refreshData) {
      store.dispatch('setRefreshData', true);
    }
    return response.data
  } catch (error) {
    let message = null;
    if (error.response) {
      message = error.response.data;
    } else {
      message = i18n.t("messages.error");
    }
    Vue.$toast.open({
      message: message,
      type: 'error'
    })
    return null
  } finally {
    store.dispatch('setRefreshData', false);
  }
}

export const del = async (path, refreshData = false) => {
  try {
    const response = await http.delete(path, mixin.methods.config())
    Vue.$toast.open({
      message: i18n.t("messages.dataDeleted"),
      type: 'success'
    })
    if (refreshData) {
      store.dispatch('setRefreshData', true);
    }
    return response.data
  } catch (error) {
    let message = null;
    if (error.response) {
      message = error.response.data;
    } else {
      message = i18n.t("messages.error");
    }
    Vue.$toast.open({
      message: message,
      type: 'error'
    })
    console.error('sss', error)
    return null

  }
}