
export default {
  data() {
    return {
      Roles: {
        ROOTADMIN: 1,
        ADMIN: 2,
        CUSTOMER: 3,
        TASWEQ: 4,
        ZABWN: 5,
      },
    };
  },
  methods: {
    validEmail(email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validName(name) {
      // eslint-disable-next-line
      var re = /^[a-zA-Z]*$/;
      return re.test(name);
    },
    validNumber(number) {
      // eslint-disable-next-line
      var re = /^\d+$/;
      return re.test(number);
    },
    config() {
      const sessionData = JSON.parse(sessionStorage.getItem("vue-session-key")); // Get the session data from session storage
      const token = sessionData.token; // Get the token from the session data
      return {
        headers: {
          Authorization: "bearer " + token,
          "Access-Control-Allow-Origin": "http://localhost:8080"

        }
      };
    },

    roleName(id) {
      return this.$store.getters.getRole(id);
    },

    checkLogin() {
      if (this.$session.get("token")) {
        return 1;
      } else {
        return 0;

      }
    },

    checkRootAdmin() {
      if (this.$session.get("role") == "RootAdmin") {
        return 1;
      } else {
        return 0;
      }
    },
    checkAdmin() {
      if (this.$session.get("role") == "Admin") {
        return 1;
      } else {
        return 0;
      }
    },
    checkStore() {
      // console.log(this.$session.get("role"));
      if (this.$session.get("role") == "Customer") {
        return 1;
      } else {
        return 0;
      }
    },
    getPaymentTextWakel(display) {
      if (display == "2") {
        return this.$t("filters.irsalRaset")
      } else if (display == "1") {
        return this.$t("filters.qabz")
      } else if (display == "3") {
        return this.$t("filters.return")
      }
      return "err";
    },
    getPaymentTextZabwn(display) {
      if (display == "2") {
        return this.$t("filters.sarf")
      } else if (display == "1") {
        return this.$t("filters.qabz")
      } else if (display == "3") {
        return this.$t("filters.sahbbb")
      }


      return "err";
    },
    getPaymentTextSairafa(display) {
      if (display == "4") {
        return this.$t("filters.dafhawala")
      } else if (display == "1") {
        return this.$t("filters.doHawala")
      } else if (display == "3") {
        return this.$t("filters.qabz")
      }
      return "err";
    },

    getPaymentTextPassengerPayment(display){
      console.log(display);
      if (display == "7") {this.$t("paymentTypeOptions.paymentPassenger")}
    },

    getPaymentText(display) {
      if (display == "1") {
        return this.$t("paymentTypeOptions.tasweqQabz")
      } else if (display == "2") {
        return this.$t("paymentTypeOptions.debt")
      } else if (display == "4") {
        return this.$t("paymentTypeOptions.tasweqDaf")
      } else if (display == "5") {
        return this.$t("paymentTypeOptions.sellProduct")
      } else if (display == "6") {
        return this.$t("paymentTypeOptions.buyProdut")
      }else if (display == "7") {
        return this.$t("paymentTypeOptions.paymentPassenger")
      }
      return "err";
    },
    getTransactionCategoryText(category) {
      if (category == "agent") {
        return this.$t("transactionCategory.wakel")
      } else if (category == "tasweq") {
        return this.$t("transactionCategory.tasweq")
      } else if (category == "zabwn") {
        return this.$t("transactionCategory.zabwn")
      } else if (category == "expense") {
        return this.$t("transactionCategory.expense")
      } else if (category == "sairafa") {
        return this.$t("transactionCategory.sairafa")
      } else if (category == "import") {
        return this.$t("transactionCategory.import")
      } else if (category == "Passenger_Payment") {
        return this.$t("transactionCategory.paymentPassenger")
      }
      return "err";
    },
    getPaymentTextClassTasweq(category){
      if (category == "agent") {
        return this.$t("transactionCategory.wakel")
      } else if (category == "tasweq") {
        return this.$t("transactionCategory.tasweq")
      } else if (category == "zabwn") {
        return this.$t("transactionCategory.zabwn")
      } else if (category == "expense") {
        return this.$t("transactionCategory.expense")
      } else if (category == "sairafa") {
        return this.$t("transactionCategory.sairafa")
      } else if (category == "import") {
        return this.$t("transactionCategory.import")
      } else if (category == "Passenger_Payment") {
        return this.$t("transactionCategory.paymentPassenger")
      }
      return "err";
    },
    getTransactionClassText(category) {
      if (category == "agent") {
        return "badge bg-success"
      } else if (category == "tasweq") {
        return "badge bg-dark"
      } else if (category == "zabwn") {
        return "badge bg-secondary"
      } else if (category == "sairafa") {
        return "badge bg-primary"
      } else if (category == "expense") {
        return "badge bg-danger"
      } else if (category == "import") {
        return "badge bg-success"
      }else if(category == "Passenger_Payment"){
        return "badge bg-success"
      }
      return "err";
    },
    getCurrencyText(display) {
      if (display == "1") {
        return this.$t("currencyTypeOptions.dinnar")
      } else if (display == "2") {
        return this.$t("currencyTypeOptions.dollar")
      } else if (display == "3") {
        return this.$t("paymentTypeOptions.convertToDollar")
      } else if (display == "4") {
        return this.$t("paymentTypeOptions.convertToDinnar")
      }
      return "err";
    },

    getPaymentTextClass(display) {
      if (display == "1") {
        return "badge-success text-bold"
      } else if (display == "2") {
        return "badge-danger text-bold"
      } else if (display == "3") {
        return "badge-warning text-dark text-bold"
      } else if (display == "4") {
        return "badge-primary text-bold"
      } else if (display == "5") {
        return "badge-dark text-bold"
      } else if (display == "6") {
        return "badge-dark text-bold"
      }else if (display == "7") {
        return "badge-dark text-bold"
      }
      return "err";
    },
    cleanDate(date) {
      if (date == null) {
        return
      }
      let thisDate = new Date(date);
      return thisDate.toLocaleDateString("en-GB");
    },
    formatDateTimeWithMinutes(dateTime) {
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true // Include AM/PM
      };

      const formattedDateTime = new Date(dateTime).toLocaleString('en-GB', options);
      return formattedDateTime;
    },
    formatDatee(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    formatDateToDDMMYYYY(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
    
      return `${day}/${month}/${year}`;
    },
    convertToYYYYMMDD(inputDate) {
      const parts = inputDate.split('/');
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];
    
      return `${year}-${month}-${day}`;
    },
    getTimeFromDate(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12;

      // Add leading zeros to minutes if necessary
      minutes = minutes < 10 ? '0' + minutes : minutes;

      var time = hours + ':' + minutes + ' ' + ampm;
      return time;
    },


    findEndDate(passedDate, days) {
      var formatedDate = new Date(passedDate);
      var theDate = new Date(formatedDate.getFullYear(), formatedDate.getMonth(), formatedDate.getDate());
      var myNewDate = new Date(theDate);
      myNewDate.setDate(myNewDate.getDate() + days);
      var toDate = this.formatDatee(myNewDate);
      return toDate
    },
    numberToArabicText(number) {
      const singleDigits = ['', 'واحد', 'اثنان', 'ثلاثة', 'أربعة', 'خمسة', 'ستة', 'سبعة', 'ثمانية', 'تسعة'];
      const teens = ['عشرة', 'أحد عشر', 'اثنا عشر', 'ثلاثة عشر', 'أربعة عشر', 'خمسة عشر', 'ستة عشر', 'سبعة عشر', 'ثمانية عشر', 'تسعة عشر'];
      const tens = ['', '', 'عشرون', 'ثلاثون', 'أربعون', 'خمسون', 'ستون', 'سبعون', 'ثمانون', 'تسعون'];
      const scales = ['', 'ألف', 'مليون', 'مليار', 'تريليون', 'كوادريليون', 'كوينتيليون', 'سكستيليون', 'سبتيليون', 'أوكتيليون', 'نونيليون', 'ديسيليون'];

      if (number === 0) {
        return 'صفر';
      }

      // Function to convert a three-digit number to its text description in Arabic
      function convertThreeDigit(num) {
        let text = '';

        let hundreds = Math.floor(num / 100);
        let tensAndOnes = num % 100;

        if (hundreds > 0) {
          text += singleDigits[hundreds] + ' مائة ';
        }

        if (tensAndOnes >= 10 && tensAndOnes <= 19) {
          text += teens[tensAndOnes - 10] + ' ';
        } else {
          let tensDigit = Math.floor(tensAndOnes / 10);
          let onesDigit = tensAndOnes % 10;

          if (tensDigit > 0) {
            text += tens[tensDigit] + ' ';
          }

          if (onesDigit > 0) {
            text += singleDigits[onesDigit] + ' ';
          }
        }

        return text.trim();
      }

      let text = '';
      let num = Math.abs(number);
      let scaleIndex = 0;

      while (num > 0) {
        let threeDigitNumber = num % 1000;
        let threeDigitText = convertThreeDigit(threeDigitNumber);

        if (threeDigitText !== '') {
          text = threeDigitText + ' ' + scales[scaleIndex] + ' ' + text;
        }

        num = Math.floor(num / 1000);
        scaleIndex++;
      }

      if (number < 0) {
        text = 'سالب ' + text;
      }

      return text.trim();
    },

    openModal(modalId){
      // eslint-disable-next-line no-undef
      var myModal = new bootstrap.Modal(document.getElementById(modalId), {
        keyboard: false,
      });
      myModal.show();
    },

  },

};