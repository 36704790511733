<template>
    <div>
        <div class="card rounded-2 col-12 col-lg-4">
            <div class="card-header">
                <h4 class="card-title mt-2 mb-2"> {{ $t("menu.settings.changePassword") }}</h4>
            </div>
            <div class="card-body">
                <form action="" @submit.prevent="changePassword">
                    <div class="form-group">
                        <label for="">{{ $t("changePasswordForm.currentPassword") }}</label>
                        <input required type="password" class="form-control" v-model="changePasswordBody.oldPassword"
                            :placeholder="$t('changePasswordForm.currentPassword')">
                    </div>
                    <div class="form-group">
                        <label for="">{{ $t("changePasswordForm.newPassword") }}</label>
                        <input required type="password" class="form-control" v-model="changePasswordBody.newPassword"
                            :placeholder="$t('changePasswordForm.newPassword')">
                    </div>
                    <div class="form-group">
                        <label for="">{{ $t("changePasswordForm.confirmPassword") }}</label>
                        <input required type="password" class="form-control" v-model="confirmPassword"
                            :placeholder="$t('changePasswordForm.confirmPassword')">
                        <small v-if="changePasswordBody.newPassword != confirmPassword" class="text-danger">{{
                            $t("passwordsDonotMatch") }}</small>
                    </div>

                    <div class="form-group">
                        <button type="submit" :disabled="changePasswordBody.newPassword != confirmPassword"
                            class="btn btn-primary">
                            <span v-if="!loading">
                                {{ $t("changePasswordForm.changePassword") }}
                            </span>
                            <div v-else class="spinner-border text-light" role="status">
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ChangePassword',

    data()
    {
        return {
            changePasswordBody: {
                userId: parseInt(this.$session.get("id")),
                oldPassword: null,
                newPassword: null
            },
            confirmPassword: null,
            loading: false,
        }
    },
    methods: {
        changePassword()
        {
            this.$loading(true);
            this.loading = true;
            this.$Axios.put(`${this.$hostname}/api/users/changePassword`, this.changePasswordBody, this.config())
                .then(() =>
                {
                    this.$loading(false);
                    this.loading = false;
                    this.changePasswordBody = {
                        userId: parseInt(this.$session.get("id")),
                        oldPassword: null,
                        newPassword: null
                    };
                    this.confirmPassword = null;
                    this.$toast.open({
                        message: this.$t("passwordChangedSuccessfully"),
                        type: 'success',
                    });
                })
                .catch((err) =>
                {
                    this.changePasswordBody = {
                        userId: parseInt(this.$session.get("id")),
                        oldPassword: null,
                        newPassword: null
                    };
                    this.confirmPassword = null;
                    this.loading = false;
                    this.$loading(false);
                    this.$toast.open({
                        message: err.response.data.toString(),
                        type: 'error',
                    });

                });
        }
    },
    mounted()
    {
        if (!this.checkLogin())
        {
            this.$router.push("/login");
            return
        }
        if (!this.checkRootAdmin())
        {
            this.$router.push("/login");
            return
        }
    }

}
</script>