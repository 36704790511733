<template>
  <div>
    <h1 class="h3 mb-3">{{ $t("shouldPayToday") }}</h1>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-1">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-sm table-striped table-hover text-center"
                v-if="transactions && transactions.items.length > 0"
              >
                <thead class="bg-dark text-light">
                  <tr>
                    <th>#</th>
                    <th>{{ $t("zabwnName") }}</th>
                    <th>
                        {{ $t("phoneNumber") }}
                      </th>
                    <th>
                      {{ $t("addUserTransaction.amount") }}
                    </th>
                   
                    <th>
                      {{ $t("addUserTransaction.paymentType") }}
                    </th>

                    <th>
                      {{ $t("addUserTransaction.date") }}
                    </th>
                    <th>
                      {{ $t("addUserTransaction.qabzDate") }}
                    </th>
                    <th>
                      {{ $t("addUserTransaction.note") }}
                    </th>
                    <!-- <th>
                      {{ $t("dropdownOptions.edit") }}
                    </th>
                    <th>
                      {{ $t("dropdownOptions.print") }}
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(transaction, index) in transactions.items"
                    :key="transaction.id"
                  >
                    <td class="py-1">
                      {{ ++index }}
                    </td>
                    <td><router-link :to="`/Customer/Profile/${transaction.userId}/5`">
                        {{ transaction.user.fullName }}
                    </router-link></td>
                    <td>{{ transaction.user.phoneNumber }}</td>
                    <td>
                      {{ transaction.amount.toLocaleString("en-US") }}
                      <span>{{ getCurrencyText(transaction.currencyType) }} </span>
                      <span
                        class="d-block my-2"
                        v-if="
                          transaction.currencyType == 3 || transaction.currencyType == 4
                        "
                        >{{ $t("addUserTransaction.convertedAmount") }}:
                        {{ transaction.convertedAmount.toLocaleString() }}</span
                      >
                    </td>
                    <td>
                      <div
                        :class="getPaymentTextClass(transaction.paymentType)"
                        class="badge me-3"
                      >
                        {{
                          isWakel
                            ? getPaymentTextWakel(transaction.paymentType)
                            : isZabwn
                            ? getPaymentTextZabwn(transaction.paymentType)
                            : getPaymentTextSairafa(transaction.paymentType)
                        }}
                      </div>
                    </td>
                    <td>
                      {{ cleanDate(transaction.date) }}
                    </td>
                    <td>{{ cleanDate(transaction.returnOn) }}</td>

                    <td>
                      {{ transaction.note }}
                    </td>
                    <!-- <td>
                      <a
                        role="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        @click="assignTransactionBody(transaction)"
                      >
                        <i class="mdi mdi-pencil big-icon"></i
                      ></a>
                    </td>
                    <td>
                      <a
                        role="button"
                        @click="printSingle(transaction)"
                        v-print="'#printMe'"
                      >
                        <i class="icon-printer big-icon"></i
                      ></a>
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <h4 v-else class="text-center text-secondary">{{ $t("noTransaction") }}</h4>
            </div>
            <Pagination
              dir="ltr"
              :pageIndex="pageIndex"
              :totalPages="totalPages"
              :onPageChange="handlePageChange"
              :onSizehange="handlePageSize"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {get} from "@/api"
import Pagination from "@/components/PaginationComponent.vue";
export default {
  name: "ShouldPayToday",
  components: {
    Pagination,
  },
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  computed: {
    totalItems() {
      return this.transactions ? this.transactions.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.transactions.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  data() {
    return {
      transactions: null,
      pageSize: 10,
      pageIndex: 1,

      isWakel: this.$route.params.type == "3",
      isZabwn: true,
      isSairafa: this.$route.params.type == "6",
    };
  },

  methods: {
    handlePageChange(newPage) {
      this.pageIndex = newPage;
      this.getTransactions();
    },
    handlePageSize(newSize) {
      this.pageSize = newSize;
      this.getTransactions();
    },
    async getTransactions() {
      this.$loading(true);
      const URL = `/api/userTransactions/getShouldPayTodayTransaction?page=${this.pageIndex}&size=${this.pageSize}`;
      this.transactions = await get(URL);
      this.$loading(false);
    },
  },
  async mounted() {
    await this.getTransactions();
  },
};
</script>
