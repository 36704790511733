<style scoped>
.modal-header,
.modal-body {
  padding: 10px !important;
}

.modal-footer {
  display: flex !important;
  justify-content: end !important;
}
</style>
<template>
  <div>
    <!-- Button trigger modal -->
    <button type="button" class="btn btn-success btn-sm rounded-1" @click="openModal">
      {{ $t("addNew") }}
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" dir="rtl">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              style="margin: unset"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="saveChanges()">
            <div class="modal-body">
              <div v-for="(field, index) in fields" :key="index">
                <div class="form-group mt-2">
                  <label>{{ field.title }}</label>
                  <input
                    class="form-control mt-2"
                    v-if="field.type === 'string'"
                    type="text"
                    :name="field.name"
                    v-model="formData[field.name]"
                  />
                  <input
                    class="form-control mt-2"
                    v-else-if="field.type === 'date'"
                    type="date"
                    :name="field.name"
                    v-model="formData[field.name]"
                  />
                  <input
                    class="form-control mt-2"
                    v-else-if="field.type === 'number'"
                    type="number"
                    :name="field.name"
                    v-model="formData[field.name]"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer" dir="ltr">
              <div>
                <button
                  type="button"
                  class="btn btn-secondary btn-sm rounded-1 mx-2"
                  data-bs-dismiss="modal"
                  id="close-btn"
                >
                  {{ $t("modal.close") }}
                </button>
                <button type="submit" class="btn btn-primary btn-sm rounded-1">
                  {{ $t("modal.save") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/api";
import $ from "jquery";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  watch: {
    isUpdating(newVal) {
      if (newVal) {
        this.formData = { ...this.initialValues };
      } else {
        this.formData = {};
      }
    },
    initialValues(newVal) {
      if (newVal) {
        this.formData = { ...newVal };
      }
    },
  },
  methods: {
    openModal() {
      this.$emit("data-updated");
      // eslint-disable-next-line no-undef
      var myModal = new bootstrap.Modal(document.getElementById("exampleModal"), {
        keyboard: false,
      });
      myModal.show();
    },
    closeModal() {
      this.show = false;
      this.$emit("close");
    },
    async saveChanges() {
      this.$loading(true);
      const isExpense = this.$route.params.isExpense == 1;
      console.log(isExpense);
      const URL = this.url + `?isExpense=${isExpense}`;
      await post(URL, this.formData, true);
      this.formData = {};
      $("#close-btn").click();
      this.$emit("data-updated");
      this.$loading(false);
    },
  },
};
</script>
