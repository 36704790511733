<style scoped>
.spinner-border {
  width: 15px !important;
  height: 15px !important;
  padding: 0%;
}

.remove-top-margin {
  margin-top: -15px;
}

.customername {
  text-decoration: none;
}


</style>
<template>
  <div>
    <div class="col-lg-12 grid-margin stretch-card">
      <!-- Add Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <form class="forms-sample" @submit.prevent="postUser()">
                <div class="form-group">
                  <label for="exampleInputUsername1">{{ $t("tasweq.name") }}</label>
                  <input
                    v-model="userBody.fullName"
                    required
                    type="text"
                    class="form-control"
                    id="exampleInputUsername1"
                  />
                </div>
                <div class="form-group remove-top-margin">
                  <label for="exampleInputUsername2">{{ $t("addUserForm.phone") }}</label>
                  <input
                    v-model="userBody.phoneNumber"
                    type="number"
                    class="form-control"
                    id="exampleInputUsername2"
                  />
                </div>
                <div class="form-group remove-top-margin">
                  <label for="exampleInputUsername3">{{
                    $t("addUserForm.address")
                  }}</label>
                  <input
                    v-model="userBody.address"
                    type="text"
                    class="form-control"
                    id="exampleInputUsername3"
                  />
                </div>
                <div class="form-group remove-top-margin">
                  <label for="exampleInputUsername4">{{ $t("addUserForm.note") }}</label>
                  <input
                    v-model="userBody.note"
                    type="text"
                    class="form-control"
                    id="exampleInputUsername4"
                  />
                </div>

                <button type="submit" class="btn btn-primary me-2" :disabled="isLoading">
                  <div
                    class="spinner-border text-light ml-3"
                    v-if="isLoading"
                    role="status"
                  >
                    <span class="visually-hidden"></span>
                  </div>
                  <span>{{ userBody.id == 0 ? $t("submit") : $t("update") }}</span>
                </button>
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  class="btn btn-light modalCloseBtn"
                >
                  {{ $t("close") }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Show Qr Modal -->
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body" v-if="selectedQR">
              <img
                v-bind:src="`http://api.qrserver.com/v1/create-qr-code/?data=${$hostname}/index.html?username=${selectedQR}&amp;size=1000*1000`"
                style="width: 100%; height: auto"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card rounded-1">
        <div class="card-body">
          <h4 class="card-title">{{ $t("menu.tasweq") }}</h4>
          <div class="d-lg-flex justify-content-between">
            <button
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              type="button"
              id="editUserBtn"
              class="d-none"
            >
              <i class="mdi mdi-account-plus"></i>
              {{ $t("addTasweq") }}
            </button>
            <button
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              type="button"
              id="addUserBtn"
              class="rounded-1 btn btn-primary btn-sm btn-icon-text mb-2"
              @click="resetProperties"
            >
              <i class="mdi mdi-account-plus"></i>
              {{ $t("addTasweq") }}
            </button>
            <div class="col col-lg-4 mb-2">
              <form @submit.prevent="getCustomers()">
                <div class="input-group">
                  <input
                    v-model="searchTerm"
                    type="text"
                    class="form-control"
                    :placeholder="$t('searchHere')"
                    :aria-label="$t('search')"
                    aria-describedby="basic-addon1"
                  />
                  <span
                    ><button class="rounded-1 btn btn-primary btn-sm mx-1">
                      {{ $t("search") }}
                    </button></span
                  >
                </div>
              </form>
            </div>
          </div>
          <div class="table-responsive">
            <table
              class="table table-striped table-sm text-center"
              v-if="customers && customers.items.length > 0"
            >
              <thead class="bg-dark text-light">
                <tr class="text-center">
                  <th>#</th>
                  <th>
                    {{ $t("TasweqTable.name") }}
                  </th>
                  <th>
                    {{ $t("customersTable.phone") }}
                  </th>
                  <th class="text-center">
                    {{ $t("customersTable.amount") }}
                  </th>
                  <th>
                    {{ $t("customersTable.amountDollar") }}
                  </th>
                  <th>
                    
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-for="(customer, index) in customers.items"
                  :key="customer.id"
                >
                  <td class="py-1">
                    {{ index + 1 + (pageIndex - 1) * pageSize }}
                  </td>
                  <td>
                    <router-link
                      class="customername"
                      :to="`/Tasweq/Profile/${customer.id}`"
                    >
                      {{ customer.fullName }}
                    </router-link>
                  </td>
                  <td>
                    {{ customer.phoneNumber }}
                  </td>
                  <td
                    :class="
                      customer.totalDebtDinnar - customer.totalPaidDinnar > 0
                        ? 'text-danger'
                        : ''
                    "
                  >
                    {{
                      (
                        customer.totalDebtDinnar - customer.totalPaidDinnar
                      ).toLocaleString("en-US")
                    }}
                    <span>{{ $t("currency.main") }} </span>
                  </td>
                  <td
                    :class="
                      customer.totalDebtDollar - customer.totalPaidDollar > 0
                        ? 'text-danger'
                        : ''
                    "
                  >
                    {{
                      (
                        customer.totalDebtDollar - customer.totalPaidDollar
                      ).toLocaleString("en-US")
                    }}
                    <span>{{ $t("currency.secondary") }} </span>
                  </td>

                  <td class="text-center">
                    <div class="btn-group dropend">
                      <i
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        class="text-primary mdi mdi-dots-vertical big-icon"
                      ></i>
                      <ul class="dropdown-menu text-center">
                        <li>
                          <a
                            class="dropdown-item text-primary dropdownOption"
                            @click="assignUserInfo(customer)"
                            >{{ $t("dropdownOptions.edit") }}</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-danger dropdownOption"
                            @click="deleteUser(customer.id)"
                            >{{ $t("dropdownOptions.delete") }}</a
                          >
                        </li>
                        <!-- <li>
                          <a
                            class="dropdown-item text-dark dropdownOption"
                            @click="selectedQR = customer.username"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal2"
                            role="button"
                            >{{ $t("dropdownOptions.viewQr") }}</a
                          >
                        </li> -->
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 v-else class="text-center text-secondary">{{ $t("noData") }}</h4>
          </div>
          <Pagination
            dir="ltr"
            :pageIndex="pageIndex"
            :totalPages="totalPages"
            :onPageChange="handlePageChange"
            :onSizehange="handlePageSize"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import Pagination from "@/components/PaginationComponent.vue";
import { get, post, del, put } from "@/api";
export default {
  name: "TasweqLssist",
  components: {
    Pagination,
  },
  data() {
    return {
      errorMessage: null,
      pageIndex: 1,
      pageSize: 10,
      showPages: 1,

      customers: null,

      searchTerm: null,

      userBody: {
        id: 0,
        fullName: null,
        phoneNumber: null,
        address: null,
        note: null,
        role: 4,
        isActive: true,
        telegramId: 0,
        totalDebt: 0,
      },

      selectedQR: null,
      isLoading: false,
    };
  },
  computed: {
    totalItems() {
      return this.customers ? this.customers.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.customers.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.getCustomers();
    },
    async handlePageSize(newSize) {
      this.pageSize = newSize;
      await this.getCustomers();
    },
    async getCustomers() {
      this.$loading(true);
      const URL = `/api/users/customers?page=${this.pageIndex}&size=${this.pageSize}&searchQuery=${this.searchTerm}&role=4`;
      this.customers = await get(URL);
      this.$loading(false);
    },
    async changeStatus(userId) {
      this.$loading(true);
      const URL = `/api/users/changeStatus?userId=${userId}`;
      await put(URL);
      await this.getCustomers();
    },
    resetProperties() {
      this.userBody = {
        id: 0,
        fullName: null,
        phoneNumber: null,
        address: null,
        note: null,
        role: 4,
        isActive: true,
        telegramId: 0,
      };
    },
    async postUser() {
      this.errorMessage = null;
      this.$loading(true);
      this.isLoading = true;
      const URL = `/api/users`;
      await post(URL, this.userBody);
      this.$loading(false);
      this.isLoading = false;
      await this.getCustomers();
      $(".modalCloseBtn").trigger("click");
      this.resetProperties();
    },

    assignUserInfo(info) {
      this.userBody = {
        id: info.id,
        fullName: info.fullName,
        phoneNumber: info.phoneNumber,
        address: info.address,
        note: info.note,
        role: 4,
        telegramId: 0,
        isActive: info.isActive,
      };
      console.log(this.userBody);
      setTimeout(() => {
        $("#editUserBtn").click();
      }, 150);
    },
    deleteUser(userId) {
      this.$confirm(this.$t("messages.deleteConfirmation"), "Question", "question")
        .then(async () => {
          this.isUploading = true;
          this.$loading(true);
          const URL = `/api/users?userId=${userId}`;
          await del(URL);
          await this.getCustomers();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
  },

  async mounted() {
    await this.getCustomers();
  },
};
</script>
