<style scoped>
.nav-link {
  cursor: pointer;
}

.chart {
  height: 400px;
}

.monthHover {
  text-decoration: none;
}
</style>
<template>
  <div>
    <ul class="nav justify-content-start bg-light shadow-sm px-1">
      <li class="nav-item my-2">
        <a
          class="nav-link btn"
          :class="selectedYear == 2023 ? 'btn-success' : 'btn-outline-success'"
          aria-current="page"
          role="button"
          >2023</a
        >
      </li>
    </ul>
    <div class="row my-3" v-if="months && months.length > 0">
      <div
        class="col col-xl-3 col-lg-4 col-md-6 col-12 mt-2"
        v-for="(month, index) in months"
        :key="index + '-' + selectedYear"
      >
        <router-link
          class="monthHover"
          :to="`/Expenses/Monthly/year/${month.year}/month/${month.month}/${$route.params.isExpense}`"
        >
          <div class="card rounded-2 shadow-sm">
            <div class="card-body text-dark">
              <div class="row">
                <div class="col-10 text-danger" style="font-size: 20px">
                  <p
                    class="text-secondary my-1"
                    style="margin-top: 0px; margin-bottom: -5px"
                  >
                    {{
                      $route.params.isExpense == "true"
                        ? $t("expenses.TitleMonth")
                        : $t("imports.TitleMonth")
                    }}
                    <span class="text-bold text-dark">{{ month.monthName }} </span>
                  </p>
                  <span class="d-block"
                    >{{ month.totalDinnar.toLocaleString() }} {{ $t("currency.main") }}
                  </span>
                  <span class="d-block"
                    >{{ month.totalDollar.toLocaleString() }}
                    {{ $t("currency.secondary") }}
                  </span>
                </div>
                <div class="col-2">
                  <i
                    class="mdi mdi-cash-multiple"
                    style="font-size: 30px; margin-right: -30px"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row my-3" v-else>
      <div class="col col-lg-12 col-12">
        <h2 class="text-center">{{ $t("noDataAvaiable") }}</h2>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "@/api";
export default {
  name: "YearMonthList",
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  data() {
    return {
      selectedYear: new Date().getUTCFullYear(),
      months: null,
    };
  },
  methods: {
    async loadMonths() {
      this.$loading(true);
      const URL = `/api/Expenses/GetMonthYearList?isExpense=${this.$route.params.isExpense}&year=${this.selectedYear}`;
      this.months = await get(URL);
      this.$loading(false);
    },
  },
  watch: {
    async $route() {
      await this.loadMonths();
    },
  },
  async mounted() {
    await this.loadMonths();
  },
};
</script>
