import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    todayExpireLoaded: false,
    refreshData: false,
    isDarkMode: localStorage.getItem('theme') === 'dark' || false,
    roles: [
      {
        id: 1,
        name: "RootAdmin"
      },
      {
        id: 2,
        name: "Admin"
      },
      {
        id: 3,
        name: "Store"
      }
    ],

    loggedUser: {
      id: null,
      username: null,
      role: null,
      token: null
    }
  },
  getters: {
    getLoggedIn: state =>
    {
      if (state.loggedUser.username != null)
      {
        return true;
      } else
      {
        return false;
      }
      // return state.loggedIn;
    },
    getRole: state => id =>
    {
      return state.roles.find(roles => roles.id === id).name;
    },

    getRolesAll: state =>
    {
      return state.roles;
    },

    getLoggedUser: state =>
    {
      return state.loggedUser;
    }
  },
  mutations: {
    toggleTheme(state) {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem('theme', state.isDarkMode ? 'dark' : 'light');
    },
    
    SET_REFRESH_DATA(state, value) {
      state.refreshData = value
    },
    setTodayExpireLoaded(state, isLoaded)
    {
      state.todayExpireLoaded = isLoaded
    },
    setLoggedUser(state, { id, username, role, token })
    {
      state.loggedUser.id = id;
      state.loggedUser.username = username;
      state.loggedUser.role = role;
      state.loggedUser.token = token;
    },
    setUserLogOut(state)
    {
      state.loggedUser = {
        id: null,
        username: null,
        role: null,
        token: null
      };
    },
    logout(state)
    {
      state.loggedUser.username = null;
      state.loggedUser.role = null;
      state.loggedUser.token = null;
    }
  },
  actions: {
    setRefreshData({ commit }, value) {
      commit('SET_REFRESH_DATA', value)
    },
    setLoggedIn(state, bol)
    {
      state.loggedIn = bol;
    }
  },
  modules: {
  }
})
