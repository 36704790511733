<style scoped>
.remove-top-margin {
  margin-top: -15px;
}
</style>
<template>
  <div>
    <h1
      class="h3 mb-3"
      :class="
        $store.state.isDarkMode ? 'dark-active-text-color' : 'light-active-text-color'
      "
    >
      {{ isExpense ? $t("expenses.TitleDaily") : $t("imports.TitleDaily") }}
    </h1>
    <div class="row">
      <div class="col-12">
        <!-- Add Modal -->
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <form class="my-4 forms-sample" @submit.prevent="postTransaction()">
                  <div class="form-group remove-top-margin">
                    <label for="exampleInput1000">{{
                      $t("addUserTransaction.expenseType")
                    }}</label>
                    <select
                      v-if="expenseTypes && expenseTypes.items.length > 0"
                      id="exampleInput1000"
                      v-model="expenseTransactionBody.expenseType"
                      class="form-control text-dark"
                    >
                      <option
                        :value="type.title"
                        v-for="type in expenseTypes.items"
                        :key="type.id"
                      >
                        {{ type.title }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group remove-top-margin">
                    <label for="exampleInput3">{{
                      $t("addUserTransaction.amount")
                    }}</label>
                    <input
                      class="form-control"
                      id="exampleInput3"
                      type="number"
                      min="0"
                      v-model.number="expenseTransactionBody.amount"
                    />
                  </div>
                  <div class="form-group remove-top-margin">
                    <label for="exampleInput4">{{
                      $t("addUserTransaction.currencyType")
                    }}</label>
                    <select
                      v-model="expenseTransactionBody.currencyType"
                      required
                      class="form-control text-dark"
                      id="exampleInput4"
                    >
                      <option value="1">{{ $t("paymentTypeOptions.dinnar") }}</option>
                      <option value="2">{{ $t("paymentTypeOptions.dollar") }}</option>
                    </select>
                  </div>
                  <div class="form-group remove-top-margin">
                    <label for="exampleInput6">{{ $t("addUserTransaction.date") }}</label>
                    <input
                      id="exampleInput6"
                      class="form-control"
                      type="date"
                      v-model="expenseTransactionBody.date"
                    />
                  </div>
                  <div class="form-group remove-top-margin">
                    <label for="exampleInput7">{{ $t("addUserTransaction.note") }}</label>
                    <input
                      id="exampleInput7"
                      class="form-control"
                      type="text"
                      v-model="expenseTransactionBody.note"
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary me-2"
                    :disabled="isLoading"
                  >
                    <div
                      class="spinner-border text-light ml-3"
                      v-if="isLoading"
                      role="status"
                    >
                      <span class="visually-hidden"></span>
                    </div>

                    <span v-else>
                      {{
                        expenseTransactionBody.id
                          ? $t("dropdownOptions.edit")
                          : $t("submit")
                      }}
                    </span>
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    class="btn btn-light modalCloseBtn"
                  >
                    {{ $t("close") }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded-1">
          <div class="card-body">
            <button
              @click="resetFields"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              type="button"
              class="rounded-1 btn btn-primary btn-sm btn-icon-text mb-2"
            >
              {{ $t("addNew") }}
            </button>
            <div class="table-responsive" v-if="expenses && expenses.items.length > 0">
              <table class="table table-hover my-0 table-striped">
                <thead style="background-color: #222e3c">
                  <tr class="text-light text-center">
                    <th>#</th>
                    <th>{{ $t("expenses.type") }}</th>
                    <th>{{ $t("expenses.amount") }}</th>
                    <th>{{ $t("expenses.date") }}</th>
                    <th>{{ $t("expenses.note") }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(expense, index) in expenses.items"
                    :key="expense.id"
                  >
                    <td>{{ index + 1 + (pageIndex - 1) * pageSize }}</td>
                    <td class="text-bold">{{ expense.expenseType }}</td>
                    <td>
                      <div class="badge badge-opacity-danger me-3">
                        {{ expense.amount.toLocaleString() }}
                        <span>{{ getCurrencyText(expense.currencyType) }} </span>
                      </div>
                    </td>
                    <td>{{ cleanDate(expense.date) }}</td>
                    <td>{{ expense.note }}</td>
                    <td>
                      <button
                        class="btn btn-primary btn-sm rounded-1"
                        @click="assignBody(expense)"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        {{ $t("edit") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination
                dir="ltr"
                :pageIndex="pageIndex"
                :totalPages="totalPages"
                :onPageChange="handlePageChange"
                :onSizehange="handlePageSize"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post } from "@/api";
import $ from "jquery";
import Pagination from "@/components/PaginationComponent.vue";
export default {
  name: "DailyExpense",
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  components: {
    Pagination,
  },
  data() {
    return {
      isLoading: false,
      expenseTypes: null,
      expenseTransactionBody: {
        id: 0,
        amount: 0,
        transactionType: "expense",
        currencyType: 1,
        paymentType: 2,
        note: null,
        date: new Date(),
        expenseType: null,
      },
      pageIndex: 1,
      pageSize: 10,
      expenses: null,
      isExpense: this.$route.params.isExpense == "true",
    };
  },
  computed: {
    totalItems() {
      return this.expenses ? this.expenses.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.expenses.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadExpenses();
    },
    async handlePageSize(newSize) {
      this.pageSize = newSize;
      await this.loadExpenses();
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    resetFields() {
      this.expenseTransactionBody = {
        id: 0,
        amount: 0,
        transactionType: "expense",
        currencyType: 1,
        paymentType: 2,
        note: null,
        date: new Date(),
        expenseType: null,
      };
    },
    assignBody(expense) {
      this.expenseTransactionBody = {
        id: expense.id,
        amount: expense.amount,
        transactionType: expense.transactionType,
        currencyType: expense.currencyType,
        paymentType: 2,
        note: expense.note,
        date: this.formatDate(expense.date),
        expenseType: expense.expenseType,
      };
    },
    async loadExpenseTypes() {
      this.$loading(true);
      const URL = `/api/expenseTypes?isExpense=${this.$route.params.isExpense}`;
      this.expenseTypes = await get(URL);
      this.$loading(false);
    },
    async loadExpenses() {
      this.$loading(true);
      const URL = `/api/Expenses/getList?isExpense=${this.$route.params.isExpense}&page=${this.pageIndex}&size=${this.pageSize}`;
      this.expenses = await get(URL);
      this.$loading(false);
    },
    async postTransaction() {
      this.$loading(true);
      this.isLoading = true;
      this.expenseTransactionBody.paymentType = parseInt(
        this.expenseTransactionBody.paymentType
      );
      this.expenseTransactionBody.currencyType = parseInt(
        this.expenseTransactionBody.currencyType
      );

      this.expenseTransactionBody.transactionType =
        this.$route.params.isExpense == "true" ? "expense" : "import";

      const URL = `/api/userTransactions`;
      await post(URL, this.expenseTransactionBody);
      await this.loadExpenses();
      this.resetFields();
      $(".modalCloseBtn").trigger("click");
      this.$loading(false);
      this.isLoading = false;
    },
  },
  watch: {
    async $route() {
      this.isExpense = this.$route.params.isExpense == "true";
      await this.loadExpenseTypes();
      await this.loadExpenses();
    },
  },
  async mounted() {
    await this.loadExpenseTypes();
    await this.loadExpenses();
  },
};
</script>
