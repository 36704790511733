<style scoped>
.remove-top-margin {
  margin-top: -15px;
}
</style>

<template>
  <div>
    <h1 class="h3 mb-3">{{ $t("flights") }}</h1>
    <CreateAttachmentModal
    type="1"
    :entity-id="selectedData"
    :onAttachmentCreation="handleAfterAddAttachment"
  />
    <!-- Add Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <form class="forms-sample" @submit.prevent="postFlight()">
              <div class="form-group">
                <label for="exampleInputUsername1">{{ $t("flight.date") }}</label>
                <date-picker
                  style="width: 100%"
                  :clearable="false"
                  v-model="data.date"
                  type="date"
                  format="DD/MM/YYYY"
                  valueType="format"
                ></date-picker>
              </div>
              <div class="form-group remove-top-margin">
                <label>{{ $t("flight.flightType") }}</label>
                <select class="form-control" v-model="data.flightType">
                  <option value="الجوية">الجوية</option>
                  <option value="البرية">البرية</option>
                </select>
              </div>
              <div class="form-group">
                <label for="exampleInputUsername111">{{
                  $t("flight.totalFlight")
                }}</label>
                <input
                  v-model.number="data.flightAmount"
                  type="number"
                  class="form-control"
                  id="exampleInputUsername111"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputUsername1112">{{
                  $t("flight.flightExpense")
                }}</label>
                <input
                  v-model.number="data.flightExpense"
                  type="number"
                  class="form-control"
                  id="exampleInputUsername1112"
                />
              </div>

              <div class="form-group remove-top-margin">
                <label for="exampleInputUsername4">{{ $t("addUserForm.note") }}</label>
                <input
                  v-model="data.note"
                  type="text"
                  class="form-control"
                  id="exampleInputUsername4"
                />
              </div>

              <button type="submit" class="btn btn-primary me-2" :disabled="isLoading">
                <div
                  class="spinner-border text-light ml-3"
                  v-if="isLoading"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>
                <span>{{ data.id == 0 ? $t("submit") : $t("update") }}</span>
              </button>
              <button
                data-bs-dismiss="modal"
                aria-label="Close"
                id="modalCloseBtn"
                class="btn btn-light"
              >
                {{ $t("close") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-1">
          <div class="card-body">
            <div class="d-lg-flex justify-content-between" style="gap: 5px">
              <button
                @click="resetProperties()"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                type="button"
                id="editUserBtn"
                class="btn btn-primary btn-sm rounded-1"
              >
                {{ $t("addWakel") }}
              </button>

              <div class="col col-lg-4 mb-2">
                <form @submit.prevent="loadFlights()">
                  <div class="input-group">
                    <input
                      v-model="searchTerm"
                      type="text"
                      class="form-control"
                      :placeholder="$t('searchHere')"
                      :aria-label="$t('search')"
                      aria-describedby="basic-addon1"
                    />
                    <span
                      ><button class="rounded-1 btn btn-primary btn-sm mx-1">
                        {{ $t("search") }}
                      </button></span
                    >
                  </div>
                </form>
              </div>
            </div>

            <div class="table-responsive mt-2">
              <table
                class="table table-striped text-center"
                v-if="flights && flights.items.length > 0"
              >
                <thead class="bg-dark text-light">
                  <tr class="text-center">
                    <th>#</th>
                    <th>
                      {{ $t("flight.date") }}
                    </th>
                    <th>
                      {{ $t("flight.flightType") }}
                    </th>
                    <th>
                      {{ $t("flight.totalFlight") }}
                    </th>
                    <th>
                      {{ $t("flight.flightExpense") }}
                    </th>
                    <th v-if="checkRootAdmin() || checkAdmin()">
                      {{ $t("flight.profit") }}
                    </th>
                    <!-- <th>
                      {{ $t("flight.totalPassengerPayment") }}
                    </th> -->
                    <th>
                      {{ $t("addUserForm.note") }}
                    </th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(flight, index) in flights.items"
                    :key="flight.id"
                  >
                    <td class="py-1">
                      {{ index + 1 + (pageIndex - 1) * pageSize }}
                    </td>

                    <td>
                      {{ cleanDate(flight.date) }}
                    </td>
                    <td>
                      {{ flight.flightType }}
                    </td>
                    <td>
                      {{ flight.flightAmount.toLocaleString() }}
                      {{ $t("filters.dollar") }}
                    </td>
                    <td>
                      {{ flight.flightExpense.toLocaleString() }}
                      {{ $t("filters.dollar") }}
                    </td>
                    <td dir="ltr" v-if="checkRootAdmin() || checkAdmin()">
                      <span
                        :class="
                          flight.flightAmount - flight.flightExpense > 0
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        $
                        {{
                          (flight.flightAmount - flight.flightExpense).toLocaleString()
                        }}
                      </span>
                    </td>
                    <!-- <td>
                      {{ flight.totalAmountDollar.toLocaleString() }}
                      {{ $t("filters.dollar") }}
                    </td> -->
                    <td>
                      {{ flight.note }}
                    </td>
                    <td>
                      <button
                        class="btn btn-primary btn-sm rounded-1 mx-1 my-1"
                        @click="assignData(flight)"
                      >
                        {{ $t("edit") }}
                      </button>
                      <button
                        class="btn btn-danger btn-sm rounded-1 mx-1 my-1"
                        @click="deleteRow(flight.id)"
                      >
                        {{ $t("delete") }}
                      </button>
                      <button
                      v-if="!flight.attachmentLink"
                        class="btn btn-warning text-dark btn-sm rounded-1 mx-1 my-1"
                        @click="openAttachmentModel(flight)"
                      >
                        {{ $t("newAttachment") }}
                      </button>
                      <div v-else>
                        <a
                        :href="$hostname+flight.attachmentLink.replaceAll('\\', '/')"
                          class="btn btn-outline-primary rounded-1 btn-sm mx-1 my-1"
                        
                        >
                          {{ $t("ViewAttachment") }}
                      </a>
                        <button
                          class="btn btn-outline-danger rounded-1 btn-sm mx-1 my-1"
                          @click="deleteAttachment(flight.attachmentId)"
                        >
                          {{ $t("DeleteAttachment") }}
                        </button>
                      </div>
                      <!-- <router-link
                        class="btn btn-success btn-sm rounded-1 mx-1 my-1"
                        :to="`/Flights/Passengers/${flight.date}/${flight.id}`"
                      >
                        {{ $t("passengers") }} ( {{ flight.totalPassengers }} )
                      </router-link> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 v-else class="text-center text-secondary">{{ $t("noData") }}</h4>
            </div>

            <Pagination
              dir="ltr"
              :pageIndex="pageIndex"
              :totalPages="totalPages"
              :onPageChange="handlePageChange"
              :onSizehange="handlePageSize"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { post, get, del } from "@/api";
import Pagination from "@/components/PaginationComponent.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import CreateAttachmentModal from "@/components/CreateAttachmentModal.vue";

export default {
  name: "FlightView",
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  components: {
    Pagination,
    DatePicker,
    CreateAttachmentModal
  },
  data() {
    return {
      isLoading: false,
      searchTerm: null,

      data: {
        id: 0,
        date: new Date(),
        flightType: "الجوية",
        flightAmount: 0,
        flightExpense: 0,
        note: null,
      },

      pageIndex: 1,
      pageSize: 10,
      flights: null,
      selectedData: "0",
    };
  },
  computed: {
    totalItems() {
      return this.flights ? this.flights.count : 0;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    paginatedItems() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      return this.flights.items.slice(startIndex, startIndex + this.pageSize);
    },
  },
  methods: {
    async handleAfterAddAttachment() {
      await this.loadFlights();
    },
    deleteAttachment(id) {
      this.$confirm(this.$t("messages.deleteConfirmation"), "Question", "question")
        .then(async () => {
          this.$loading(true);
          const URL = `/api/uploader?id=${id}`;
          await del(URL);
          await this.loadFlights();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    openAttachmentModel(data) {
      this.selectedData = data.id.toString();
      this.openModal("CreateAttachmentModal");
    },
    assignData(passedFlight) {
      this.data = {
        id: passedFlight.id,
        date: this.formatDateToDDMMYYYY(new Date(passedFlight.date)),
        flightType: passedFlight.flightType,
        flightAmount: passedFlight.flightAmount,
        flightExpense: passedFlight.flightExpense,
        note: passedFlight.note,
      };

      // eslint-disable-next-line no-undef
      var myModal = new bootstrap.Modal(document.getElementById("exampleModal"), {
        keyboard: false,
      });
      myModal.show();
    },
    async handlePageChange(newPage) {
      this.pageIndex = newPage;
      await this.loadFlights();
    },
    async handlePageSize(newSize) {
      this.pageSize = newSize;
      await this.loadFlights();
    },
    async loadFlights() {
      this.$loading(true);
      const URL = `/api/flights?page=${this.pageIndex}&size=${this.pageSize}&search=${this.searchTerm}`;
      this.flights = await get(URL);
      this.$loading(false);
    },
    resetProperties() {
      this.data = {
        id: 0,
        date: new Date(),
        flightType: "الجوية",
        flightAmount: 0,
        flightExpense: 0,
        note: null,
      };
    },
    deleteRow(id) {
      this.$confirm(
        this.$t("messages.deleteConfirmation"),
        this.$t("messages.areYouSure"),
        "question"
      )
        .then(async () => {
          this.$loading(true);
          const URL = `/api/flights?entityId=${id}`;
          await del(URL, false);
          await this.loadFlights();
          this.$loading(false);
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    async postFlight() {
      this.data.date = this.convertToYYYYMMDD(this.data.date);
      this.$loading(true);
      this.isLoading = true;
      const URL = `/api/flights`;
      await post(URL, this.data);
      this.$loading(false);
      this.isLoading = false;
      await this.loadFlights();
      $("#modalCloseBtn").click();
    },
  },
  async mounted() {
    await this.loadFlights();
  },
};
</script>
