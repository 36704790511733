<style scoped>
.navbar-brand-wrapper {
  background-color: #343a40 !important;
}
.navbar-brand-wrapper-dark {
  background-color: var(--dark-main-color) !important;
}

.navbar-menu-wrapper {
  justify-content: space-between !important;
  background-color: white;
}

.navbar-menu-wrapper-dark {
  background-color: var(--dark-secondary-color) !important;
  justify-content: space-between !important;
}

.navbar-content {
  border-radius: 10px;
  background-color: #343a40 !important;
  padding: 10px;
}

.navbar-content-dark {
  border-radius: 10px;
  background-color: var(--dark-main-color) !important;
  padding: 10px;
}

.light-active-text-color-nav {
  color: white;
}
</style>

<template>
  <div>
    <nav
      style="direction: rtl"
      class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row"
    >
      <div
        class="text-center d-flex navbar-brand-wrapper align-items-center justify-content-center"
        :class="isDarkMode ? 'navbar-brand-wrapper-dark' : ''"
      >
        <div>
          <router-link
            to="/Report/Business"
            class="navbar-brand brand-logo rounded p-1"
            style="font-size: 2.5rem"
          >
            <img
              src="../assets/logo.png"
              alt="logo"
              style="width: 75px; height: auto"
              class="rounded"
            />
          </router-link>
        </div>
      </div>
      <div
        class="navbar-menu-wrapper"
        :class="isDarkMode ? 'navbar-menu-wrapper-dark' : ''"
      >
        <div
          class="d-flex align-items-center justify-content-between"
          :class="isDarkMode ? 'navbar-content-dark' : 'navbar-content'"
        >
          <ul class="navbar-nav mb-2">
            <li class="nav-item font-weight-semibold d-none d-lg-block ms-0">
              <h3
                :class="
                  isDarkMode ? 'dark-active-text-color' : 'light-active-text-color-nav'
                "
              >
                شركة سبيل زمزم للحج و العمرة
              </h3>
            </li>
          </ul>
          <div class="d-flex" style="gap: 10px">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link count-indicator" id="notificationDropdown">
                  <h6
                    class="mt-2"
                    :class="
                      isDarkMode
                        ? 'dark-active-text-color'
                        : 'light-active-text-color-nav'
                    "
                  >
                    {{ $session.get("tenantName") }}
                  </h6>
                </a>
              </li>
              <li class="nav-item">
                <div class="dark-icon">
                  <i
                    v-if="!isDarkMode"
                    class="mdi mdi-brightness-4 text-light"
                    @click="toggleTheme"
                  ></i>
                  <i
                    v-else
                    class="mdi mdi-lightbulb-on-outline text-light"
                    @click="toggleTheme"
                  ></i>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link count-indicator"
                  id="countDropdown"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    class="icon-bell"
                    :class="
                      isDarkMode
                        ? 'dark-active-text-color'
                        : 'light-active-text-color-nav'
                    "
                  ></i>
                  <span class="count"></span>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
                  aria-labelledby="countDropdown"
                >
                  <a class="dropdown-item py-3">
                    <p class="mb-0 font-weight-medium float-left">
                      {{ $t("headerTabs.noNotification") }}
                    </p>
                  </a>
                </div>
              </li>
              <li class="nav-item dropdown d-none d-lg-block user-dropdown">
                <a
                  class="nav-link"
                  id="UserDropdown"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    class="img-xs rounded-circle"
                    src="images/faces/face8.jpg"
                    alt="Profile image"
                  />
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right navbar-dropdown"
                  aria-labelledby="UserDropdown"
                >
                  <div class="dropdown-header text-center">
                    <img
                      class="img-md rounded-circle"
                      src="images/faces/face8.jpg"
                      alt="Profile image"
                    />
                    <p class="mb-1 mt-3 font-weight-semibold">
                      {{ $session.get("fullName") }}
                    </p>
                  </div>

                  <a class="dropdown-item" role="button" @click="signOut()"
                    ><i class="dropdown-item-icon mdi mdi-power text-primary me-2"></i
                    >{{ $t("headerTabs.signOut") }}</a
                  >
                </div>
              </li>
            </ul>
          </div>
          <button class="btn d-lg-none" type="button" @click="toggleSideBar">
            <span class="mdi mdi-menu text-light"></span>
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import $ from "jquery";
import { mapState, mapMutations } from "vuex";
export default {
  name: "NavBar",
  watch: {
    $route() {
      $(".sidebar").removeClass("active");
    },
  },
  computed: {
    ...mapState(["isDarkMode"]),
  },

  methods: {
    ...mapMutations(["toggleTheme"]),
    signOut() {
      this.$loading(true);
      setTimeout(() => {
        this.$session.destroy();
        this.$store.commit("logout");
        this.$router.push("/login");
        this.$loading(false);
      }, 400);
    },
    toggleSideBar() {
      if ($(".sidebar").hasClass("active")) {
        $(".sidebar").removeClass("active");
      } else {
        $(".sidebar").addClass("active");
      }
    },
  },
};
</script>
