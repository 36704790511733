<style>
/* .chart {
  height: 250px;

} */
.chart {
  height: 400px;
}

.notification-text {
  text-decoration: none;
  color: black;
}
.notification-text:hover {
  cursor: pointer;
}
</style>

<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="qasaContent">
              <table class="table text-center" v-if="qasaResult">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ $t("IQD") }}</th>
                    <th scope="col">{{ $t("dollar") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t("qasa.dafWakel") }}</td>
                    <td>
                      <span class="badge badge-success">{{
                        qasaResult.dafWakelDinnarIn.toLocaleString()
                      }}</span>
                    </td>
                    <td>
                      <span class="badge badge-success">{{
                        qasaResult.dafWakelDollarIn.toLocaleString()
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("qasa.dafDewn") }}</td>
                    <td>
                      <span class="badge badge-success">{{
                        qasaResult.dafDewnDinnarIn.toLocaleString()
                      }}</span>
                    </td>
                    <td>
                      <span class="badge badge-success">{{
                        qasaResult.dafDewnDollarIn.toLocaleString()
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("qasa.sahbDewn") }}</td>
                    <td>
                      <span class="badge badge-danger">{{
                        qasaResult.sahbDewnDinnarOut.toLocaleString()
                      }}</span>
                    </td>
                    <td>
                      <span class="badge badge-danger">{{
                        qasaResult.sahbDewnDollarOut.toLocaleString()
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("qasa.qabzTasweq") }}</td>
                    <td>
                      <span class="badge badge-success">{{
                        qasaResult.qabzTasweqDinnarIn.toLocaleString()
                      }}</span>
                    </td>
                    <td>
                      <span class="badge badge-success">{{
                        qasaResult.qabzTasweqDollarIn.toLocaleString()
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("qasa.dafTasweq") }}</td>
                    <td>
                      <span class="badge badge-danger">{{
                        qasaResult.dafTasweqDinnarOut.toLocaleString()
                      }}</span>
                    </td>
                    <td>
                      <span class="badge badge-danger">{{
                        qasaResult.dafTasweqDollarOut.toLocaleString()
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("qasa.masaref") }}</td>
                    <td>
                      <span class="badge badge-danger">{{
                        qasaResult.masarefDinnarOut.toLocaleString()
                      }}</span>
                    </td>
                    <td>
                      <span class="badge badge-danger">{{
                        qasaResult.masarefDollarOut.toLocaleString()
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div class="qasaQadema" v-if="qasaQadima">
              <h6 class="text-bold">{{ $t("qasa.qasaQadema") }}</h6>
              <div class="row">
                <div class="col col-lg-6 col-12">
                  <div class="form-group">
                    <label for="">{{ $t("qasa.dinnar") }}</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="qasaQadima.qasaQadimaDinnar"
                      :disabled="qasaQadima.locked"
                    />
                  </div>
                </div>
                <div class="col col-lg-6 col-12">
                  <div class="form-group">
                    <label for="">{{ $t("qasa.dollar") }}</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="qasaQadima.qasaQadimaDollar"
                      :disabled="qasaQadima.locked"
                    />
                  </div>
                </div>
                <div class="col col-lg-6 col-12" style="margin-top: -15px">
                  <div class="form-group">
                    <label for="">{{ $t("qasa.startDate") }}</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="qasaQadima.startTime"
                      :disabled="qasaQadima.locked"
                    />
                  </div>
                </div>
                <div class="col col-lg-2 col-12" v-if="!qasaQadima.locked">
                  <div class="form-group">
                    <label for="">{{ $t("qasa.started") }}</label>
                    <div class="form-switch">
                      <input
                        @change="updateQasaQadima(true)"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        :checked="qasaQadima.started"
                      />
                    </div>
                  </div>
                </div>
                <div class="col col-lg-2 col-6" v-if="!qasaQadima.locked">
                  <button
                    class="btn btn-primary btn-sm rounded-1 mt-3"
                    @click="updateQasaQadima()"
                  >
                    {{ $t("update") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="notifications" v-if="todayNotifications">
      <div
        class="alert alert-danger"
        role="alert"
        v-for="notification in todayNotifications"
        :key="'today-notification-' + notification.id"
      >
       <router-link :to="notification.routeTo" class="notification-text">
        {{ notification.message }}
       </router-link>
      </div>
    </div>
    <div class="row" v-if="checkRootAdmin()">
      <div class="col col-lg-4 col-12 mt-2">
        <div
          class="card rounded-2 shadow-sm"
          style="border-bottom: #e91e63 solid 3px"
          :class="$store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode'"
        >
          <div class="card-body text-dark">
            <div class="row">
              <div class="col-10 text-danger" style="font-size: 20px">
                <p
                  class="text-bold"
                  style="margin-top: 0px"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                >
                  {{ $t("dashboardStatistic.agentDebt") }}
                </p>
                <span
                  class="d-block text-bold"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  >{{ resultAgentsDebt.totalDinnar.toLocaleString("en-US") }}
                  {{ $t("currency.main") }}</span
                >
                <span
                  class="d-block text-bold"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  >{{ resultAgentsDebt.totalDollar.toLocaleString("en-US") }}
                  {{ $t("currency.secondary") }}</span
                >
              </div>
              <div class="col-2">
                <i
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  class="mdi mdi-account-group"
                  style="font-size: 30px; margin-right: -30px"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-12 mt-2">
        <div
          class="card rounded-2 shadow-sm"
          style="border-bottom: #5e50f9 solid 3px"
          :class="$store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode'"
        >
          <div class="card-body text-dark">
            <div class="row">
              <div class="col-10 text-danger" style="font-size: 20px">
                <p
                  class="text-bold"
                  style="margin-top: 0px"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                >
                  {{ $t("dashboardStatistic.tasweq") }}
                </p>
                <span
                  class="d-block text-bold"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  >{{ resultTasweqDebt.totalDinnar.toLocaleString("en-US") }}
                  {{ $t("currency.main") }}</span
                >
                <span
                  class="d-block text-bold"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  >{{ resultTasweqDebt.totalDollar.toLocaleString("en-US") }}
                  {{ $t("currency.secondary") }}</span
                >
              </div>
              <div class="col-2">
                <i
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  class="mdi mdi-account-group"
                  style="font-size: 30px; margin-right: -30px"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-12 mt-2">
        <div
          class="card rounded-2 shadow-sm"
          style="border-bottom: #f9b850 solid 3px"
          :class="$store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode'"
        >
          <div class="card-body text-dark">
            <div class="row">
              <div class="col-10 text-danger" style="font-size: 20px">
                <p
                  class="text-bold"
                  style="margin-top: 0px"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                >
                  {{ $t("dashboardStatistic.zabwn") }}
                </p>
                <span
                  class="d-block text-bold"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  >{{ resultZabwnDebt.totalDinnar.toLocaleString("en-US") }}
                  {{ $t("currency.main") }}</span
                >
                <span
                  class="d-block text-bold"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  >{{ resultZabwnDebt.totalDollar.toLocaleString("en-US") }}
                  {{ $t("currency.secondary") }}</span
                >
              </div>
              <div class="col-2">
                <i
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  class="mdi mdi-account-group"
                  style="font-size: 30px; margin-right: -30px"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-12 mt-3">
        <div
          class="card rounded-2 shadow-sm"
          style="border-bottom: #e91e63 solid 3px"
          :class="$store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode'"
        >
          <div class="card-body text-dark">
            <div class="row">
              <div class="col-10 text-danger" style="font-size: 20px">
                <p
                  class="text-bold"
                  style="margin-top: 0px"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                >
                  {{ $t("dashboardStatistic.sairafaDebt") }}
                </p>
                <span
                  class="d-block text-bold"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  >{{ resultSairafaDebt.totalDinnar.toLocaleString("en-US") }}
                  {{ $t("currency.main") }}</span
                >
                <span
                  class="d-block text-bold"
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  >{{ resultSairafaDebt.totalDollar.toLocaleString("en-US") }}
                  {{ $t("currency.secondary") }}</span
                >
              </div>
              <div class="col-2">
                <i
                  :class="
                    $store.state.isDarkMode
                      ? 'dark-active-text-color'
                      : 'light-active-text-color'
                  "
                  class="mdi mdi-bank-transfer"
                  style="font-size: 30px; margin-right: -30px"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-12 mt-3" v-if="qasaResult">
        <a role="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
          <div
            class="card rounded-2 shadow-sm"
            style="border-bottom: #e91e63 solid 3px"
            :class="$store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode'"
          >
            <div class="card-body text-dark">
              <div class="row">
                <div class="col-10 text-danger" style="font-size: 20px">
                  <p
                    class="text-bold"
                    style="margin-top: 0px"
                    :class="
                      $store.state.isDarkMode
                        ? 'dark-active-text-color'
                        : 'light-active-text-color'
                    "
                  >
                    {{ $t("dashboardStatistic.qasa") }}
                  </p>
                  <span
                    class="d-block text-bold"
                    :class="
                      $store.state.isDarkMode
                        ? 'dark-active-text-color'
                        : 'light-active-text-color'
                    "
                    >{{ qasaResult.qasaResultDinnar.toLocaleString("en-US") }}
                    {{ $t("currency.main") }}</span
                  >
                  <span
                    class="d-block text-bold"
                    :class="
                      $store.state.isDarkMode
                        ? 'dark-active-text-color'
                        : 'light-active-text-color'
                    "
                    >{{ qasaResult.qasaResultDollar.toLocaleString("en-US") }}
                    {{ $t("currency.secondary") }}</span
                  >
                </div>
                <div class="col-2">
                  <i
                    :class="
                      $store.state.isDarkMode
                        ? 'dark-active-text-color'
                        : 'light-active-text-color'
                    "
                    class="mdi mdi-safe"
                    style="font-size: 30px; margin-right: -30px"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row mt-3" v-if="dailyStatistics">
      <div class="col col-lg-3 col-12 mt-2">
        <div class="card rounded-2 shadow-sm" style="border-bottom: #5e50f9 solid 3px">
          <div class="card-body text-dark">
            <div class="row">
              <div class="col-10 text-danger" style="font-size: 20px">
                <p class="text-secondary" style="margin-top: 0px; margin-bottom: -5px">
                  {{ $t("dashboardStatistic.totalUsers") }}
                </p>
                {{ dailyStatistics.totalCustomersCount }}
              </div>
              <div class="col-2">
                <i
                  class="mdi mdi-account-group"
                  style="font-size: 30px; margin-right: -30px"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-lg-3 col-12 mt-2">
        <div class="card rounded-2 shadow-sm" style="border-bottom: #f2a654 solid 3px">
          <div class="card-body text-dark">
            <div class="row">
              <div class="col-10 text-danger" style="font-size: 20px">
                <p class="text-secondary" style="margin-top: 0px; margin-bottom: -5px">
                  {{ $t("dashboardStatistic.totalOweUsers") }}
                </p>
                {{ dailyStatistics.debtorsCount }}
              </div>
              <div class="col-2">
                <i
                  class="mdi mdi-account-group"
                  style="font-size: 30px; margin-right: -30px"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-lg-3 col-12 mt-2">
        <div class="card rounded-2 shadow-sm" style="border-bottom: #e91e63 solid 3px">
          <div class="card-body text-dark">
            <div class="row">
              <div class="col-10 text-danger" style="font-size: 20px">
                <p class="text-secondary" style="margin-top: 0px; margin-bottom: -5px">
                  {{ $t("dashboardStatistic.totalAllDebts") }}
                </p>
                {{ dailyStatistics.totalCustomersDebt.toLocaleString("en-US") }}
                <span>{{ $t("currency.main") }} </span>
              </div>
              <div class="col-2">
                <i
                  class="mdi mdi-cash-multiple"
                  style="font-size: 30px; margin-right: -30px"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-lg-3 col-12 mt-2" v-if="weeklyParam.reports">
        <div class="card rounded-2 shadow-sm" style="border-bottom: #34b1aa solid 3px">
          <div class="card-body text-dark">
            <div
              class="text-success d-flex justify-content-between"
              style="font-size: 20px"
            >
              <p class="text-secondary" style="margin-top: 0px; margin-bottom: -5px">
                {{ $t("dashboardStatistic.totalPaidToday") }}
              </p>
              <p>
                {{
                  weeklyParam.reports[
                    weeklyParam.reports.length - 1
                  ].totalPaid.toLocaleString("en-US")
                }}
                <span>{{ $t("currency.main") }} </span>
              </p>
            </div>
            <div
              class="text-danger d-flex justify-content-between"
              style="font-size: 20px"
            >
              <p class="text-secondary" style="margin-top: 0px; margin-bottom: -5px">
                {{ $t("dashboardStatistic.totalDebtToday") }}
              </p>
              <p>
                {{
                  weeklyParam.reports[
                    weeklyParam.reports.length - 1
                  ].totalDebt.toLocaleString("en-US")
                }}
                <span>{{ $t("currency.main") }} </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card rounded-2 mt-2">
        <v-chart class="chart mt-4" :option="multiBar.option" autoresize />
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "@/api";
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import { mapState } from "vuex";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
} from "echarts/components";

import {} from "echarts/components";
import VChart from "vue-echarts";
use([
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  MarkPointComponent,
]);

export default {
  components: {
    "v-chart": VChart,
  },
  /* eslint-disable */
  name: "Dashboard",
  computed: {
    ...mapState(["isDarkMode"]),
  },
  async mounted() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
    // if (!this.checkRootAdmin()) {
    //   this.$router.push("/login");
    //   return;
    // }

    await this.getAgentDebt();
    await this.getTasweqDebt();
    await this.getZabwnDebt();
    await this.getSairafaDebt();
    await this.getQasaResult();
    await this.getQasaQadima();
    await this.loadTodayNotifications();
    //this.getDailyReport();

    //this.getWeeklyReport();
    //this.getDailyStatisticReport();
  },
  computed: {
    chartData() {
      return this.weeklyBar.chartData;
    },
    chartOptions() {
      return this.weeklyBar.chartOptions;
    },
  },

  data() {
    return {
      todayNotifications: null,
      qasaResult: null,
      resultAgentsDebt: {
        totalDinnar: 0,
        totalDollar: 0,
      },
      resultTasweqDebt: {
        totalDinnar: 0,
        totalDollar: 0,
      },
      resultZabwnDebt: {
        totalDinnar: 0,
        totalDollar: 0,
      },
      resultSairafaDebt: {
        totalDinnar: 0,
        totalDollar: 0,
      },

      agentsDebt: null,
      tasweqsDebt: null,
      zabwnDebt: null,
      sairafaDebt: null,

      qasaQadima: null,

      dailyStatistics: null,
      multiBar: {
        option: {
          textStyle: {
            fontFamily: "Arabic",
            fontSize: "10px",
          },

          title: {
            show: true,
            text: this.$t("lastSevenDays"),
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },

          legend: {
            data: [
              this.$t("paymentTypeOptions.paid"),
              this.$t("paymentTypeOptions.debt"),
            ],
          },
          toolbox: {
            show: true,
            orient: "vertical",
            left: "right",
            top: "center",
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ["line", "bar", "stack"] },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: true },
              data: [],
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: this.$t("paymentTypeOptions.paid"),
              type: "bar",
              barGap: 0,
              label: this.$t("paymentTypeOptions.paid"),
              color: "green",
              emphasis: {
                focus: "series",
              },

              data: [],
            },
            {
              name: this.$t("paymentTypeOptions.debt"),
              type: "bar",
              label: this.$t("paymentTypeOptions.debt"),
              color: "#f96868",
              emphasis: {
                focus: "series",
              },
              data: [],
            },
          ],
        },
      },
      weeklyBar: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: this.$t("paymentTypeOptions.debt"),
              backgroundColor: "#f87979",
              data: [],
            },
            {
              label: this.$t("paymentTypeOptions.paid"),
              backgroundColor: "#22961e",
              data: [],
            },
          ],
        },
        chartOptions: {
          responsive: true,
        },
      },

      dailyParams: {
        from: this.formatDatee(new Date()),
        to: this.formatDatee(new Date()),
        dailyReports: null,
      },
      weeklyParam: {
        from: this.findEndDate(this.formatDatee(new Date()), -7),
        to: this.formatDatee(new Date()),
        reports: null,
      },
    };
  },

  methods: {
    async loadTodayNotifications() {
      const URL = "/api/Notifications/today";

      this.todayNotifications = await get(URL);
    },

    setAgentDebt(retrivedDebt) {
      const totalDinnar = retrivedDebt.find((x) => x.currencyType == 1);
      const totalDollar = retrivedDebt.find((x) => x.currencyType == 2);
      this.resultAgentsDebt.totalDinnar = totalDinnar ? totalDinnar.sumRemaining : 0;
      this.resultAgentsDebt.totalDollar = totalDollar ? totalDollar.sumRemaining : 0;
    },
    setTasweqDebt(retrivedDebt) {
      const totalDinnar = retrivedDebt.find((x) => x.currencyType == 1);
      const totalDollar = retrivedDebt.find((x) => x.currencyType == 2);
      this.resultTasweqDebt.totalDinnar = totalDinnar ? totalDinnar.sumRemaining : 0;
      this.resultTasweqDebt.totalDollar = totalDollar ? totalDollar.sumRemaining : 0;
    },
    setZabwnDebt(retrivedDebt) {
      const totalDinnar = retrivedDebt.find((x) => x.currencyType == 1);
      const totalDollar = retrivedDebt.find((x) => x.currencyType == 2);
      this.resultZabwnDebt.totalDinnar = totalDinnar ? totalDinnar.sumRemaining : 0;
      this.resultZabwnDebt.totalDollar = totalDollar ? totalDollar.sumRemaining : 0;
    },
    setSairafaDebt(retrivedDebt) {
      const totalDinnar = retrivedDebt.find((x) => x.currencyType == 1);
      const totalDollar = retrivedDebt.find((x) => x.currencyType == 2);
      this.resultSairafaDebt.totalDinnar = totalDinnar ? totalDinnar.sumRemaining : 0;
      this.resultSairafaDebt.totalDollar = totalDollar ? totalDollar.sumRemaining : 0;
    },
    async updateQasaQadima(updateState = false) {
      this.$loading(true);
      await this.$Axios
        .put(
          `${this.$hostname}/api/TransactionReports/UpdateQasaQadima?isChangeState=${updateState}`,
          this.qasaQadima,
          this.config()
        )
        .then(async () => {
          await this.getQasaQadima();
          this.$toast.open({
            message: this.$t("dataupdated"),
            type: "success",
          });
          if (updateState) {
            await this.getQasaResult();
          }
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
    async getQasaQadima() {
      this.$loading(true);
      await this.$Axios
        .get(`${this.$hostname}/api/TransactionReports/GetQasaQadima`, this.config())
        .then((res) => {
          this.qasaQadima = res.data;
          this.qasaQadima.startTime = this.formatDatee(this.qasaQadima.startTime);
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
    async getQasaResult() {
      this.$loading(true);
      await this.$Axios
        .get(`${this.$hostname}/api/TransactionReports/GenerateQasa`, this.config())
        .then((res) => {
          this.qasaResult = res.data;

          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
    async getAgentDebt() {
      this.$loading(true);
      await this.$Axios
        .get(
          `${this.$hostname}/api/TransactionReports/GenerateSpecificTransactionReport?type=agent`,
          this.config()
        )
        .then((res) => {
          this.agentsDebt = res.data;
          if (this.agentsDebt) {
            this.setAgentDebt(this.agentsDebt);
          }
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
    async getTasweqDebt() {
      this.$loading(true);
      await this.$Axios
        .get(
          `${this.$hostname}/api/TransactionReports/GenerateSpecificTransactionReport?type=tasweq`,
          this.config()
        )
        .then((res) => {
          this.tasweqsDebt = res.data;
          if (this.tasweqsDebt) {
            this.setTasweqDebt(this.tasweqsDebt);
          }
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
    async getZabwnDebt() {
      this.$loading(true);
      await this.$Axios
        .get(
          `${this.$hostname}/api/TransactionReports/GenerateSpecificTransactionReport?type=zabwn`,
          this.config()
        )
        .then((res) => {
          this.zabwnDebt = res.data;
          if (this.zabwnDebt) {
            this.setZabwnDebt(this.zabwnDebt);
          }
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
    async getSairafaDebt() {
      this.$loading(true);
      await this.$Axios
        .get(
          `${this.$hostname}/api/TransactionReports/GenerateSpecificTransactionReport?type=sairafa`,
          this.config()
        )
        .then((res) => {
          this.sairafaDebt = res.data;
          if (this.sairafaDebt) {
            this.setSairafaDebt(this.sairafaDebt);
          }
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
    getDailyStatisticReport() {
      this.$loading(true);
      this.$Axios
        .get(
          `${this.$hostname}/api/userTransactions/generateDashboardStatisticsReport`,
          this.config()
        )
        .then((res) => {
          this.dailyStatistics = res.data;
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
    getDailyReport() {
      this.$loading(true);
      this.$Axios
        .get(
          `${this.$hostname}/api/userTransactions/generateReport?from=${this.dailyParams.from}&to=${this.dailyParams.to}`,
          this.config()
        )
        .then((res) => {
          this.dailyParams.dailyReports = res.data;

          // if (this.dailyParams.dailyReports)
          // {
          // }
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },

    getWeeklyReport() {
      this.$loading(true);
      this.$Axios
        .get(
          `${this.$hostname}/api/userTransactions/generateReportList?from=${this.weeklyParam.from}&to=${this.weeklyParam.to}`,
          this.config()
        )
        .then((res) => {
          this.weeklyParam.reports = res.data;
          if (this.weeklyParam.reports) {
            this.weeklyParam.reports.forEach((e) => {
              this.multiBar.option.xAxis[0].data.push(this.cleanDate(e.date));
              this.multiBar.option.series[0].data.push(e.totalPaid);
              this.multiBar.option.series[1].data.push(e.totalDebt);
            });
          }
          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
        });
    },
  },
};
</script>
