<template>
  <div>
    <!-- Button trigger modal -->
    <button
      type="button"
      class="btn btn-primary d-none"
      data-bs-toggle="modal"
      id="openModal"
      data-bs-target="#staticBackdrop"
    >
      Launch static backdrop modal
    </button>

    <!-- Modal -->
    <div
      dir="ltr"
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header" v-if="selectedTitle">
            <h5 class="modal-title" id="staticBackdropLabel">{{ selectedTitle }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" >
            <div v-if="specificFile" style="text-align: left;">
                <pre v-html="highlightKeywords(specificFile)"></pre>
              </div>
            <!-- <p style="text-align: left;"></p> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="files">
      <div
        class="col col-lg-3 col-12"
        v-for="(file, index) in files"
        :key="'file-' + index"
      >
        <div class="card rounded-2">
          <div
            class="card-body d-flex justify-content-center flex-column align-items-center"
          >
            <i class="fa fa-file" style="font-size: 35px"></i>
            <a
              role="button"
              @click="getSpecificLogFile(file)"
              class="mt-2 text-primary"
              >{{ getLastElement(file) }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "@/api";
import $ from "jquery";
export default {
  name: "LogsList",
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  data() {
    return {
      files: null,
      specificFile: null,
      selectedTitle: null,
    };
  },
  methods: {
    getLastElement(inputString) {
      const elements = inputString.split("\\");
      return elements[elements.length - 1];
    },
    async loadLogFiles() {
      this.$loading(true);
      const URL = "/api/logFiles";
      this.files = await get(URL);
      this.$loading(false);
    },

    async getSpecificLogFile(path) {
      this.specificFile = null;
      this.selectedTitle = null;
      const fileName = this.getLastElement(path);
      this.selectedTitle = fileName
      this.$loading(true);
      const URL = `/api/logFiles/${fileName}`;
      this.specificFile = await get(URL);

      if (this.specificFile) {
        $("#openModal").click();
      }
      this.$loading(false);
    },
    highlightKeywords(text) {
      const highlightStartTag = '<span class="text-danger">';
      const highlightEndTag = '</span>';

      const regex = /(.*\[ERR\].*)/g;
      const highlightedText = text.replace(regex, `${highlightStartTag}$1${highlightEndTag}`);

      return highlightedText;
    }
  },

  async mounted() {
    await this.loadLogFiles();
  },
};
</script>
