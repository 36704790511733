<style scoped>
.nav-link {
  cursor: pointer;
}

.chart {
  height: 400px;

}

.monthHover {
  text-decoration: none;
}
</style>

<template>
  <div>
    <div class="home-tab">
      <div class="d-sm-flex align-items-center justify-content-between border-bottom">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" v-for="year in years " :key="year.id">
            <a class="nav-link" :class="year.selected ? 'active' : ''" id="home-tab">{{ year.year }}</a>
          </li>
        </ul>
        <div>
          <div class="btn-wrapper">
            <a href="#" class="btn btn-otline-dark"><i class="icon-printer"></i> {{ $t("exportOptions.print") }}</a>
            <a href="#" class="btn btn-primary text-white"><i class="icon-download"></i>
              {{ $t("exportOptions.exportToExcel") }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col col-md-4 col-lg-3 col-12 mt-2" v-for="month in months" :key="month.id">
        <router-link class="monthHover" :to="`/Reports/SingleMonth/Month/${month.month.id}/year/${month.yearId}`">
          <div class="card rounded-2 shadow-sm">
            <div class="card-body text-dark">
              <div class="row">
                <div class="col-10 text-danger" style="font-size: 20px;">
                  <p class="text-secondary" style="margin-top: 0px; margin-bottom: -5px;">{{ month.month.nameAr }}</p>
                  {{ month.actualDebt.toLocaleString('en-US') }} <span>{{ $t("currency.main") }} </span>
                </div>
                <div class="col-2">
                  <i class="mdi mdi-cash-multiple" style="font-size: 30px; margin-right: -30px;"></i>
                </div>
              </div>
            </div>
          </div>
        </router-link>

      </div>
    </div>

    <v-chart class="chart mt-4" :option="multiBar.option" autoresize />
  </div>
</template>
<script>
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import
{
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
} from "echarts/components";

import
{
} from "echarts/components";
import VChart from "vue-echarts";
use([
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  MarkPointComponent,
]);

export default {
  name: 'YearsReport',
  components: {
    'v-chart': VChart
  },
  created()
  {
    if (!this.checkLogin())
    {
      this.$router.push("/login");
      return
    }
  },
  data()
  {

    return {
      years: null,
      months: null,

      multiBar: {
        option: {
          textStyle: {
            fontFamily: "Arabic",
            fontSize: '10px'
          },

          title: {
            show: true,
            text: this.$tc('graphText.yearlyBarText', 0, { year: 2023 })
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },

          legend: {
            data: [this.$t("paymentTypeOptions.paid"), this.$t("paymentTypeOptions.debt")]
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar', 'stack'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          xAxis: [
            {
              type: 'category',
              axisTick: { show: true },
              data: []
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: this.$t("paymentTypeOptions.paid"),
              type: 'bar',
              barGap: 0,
              label: this.$t("paymentTypeOptions.paid"),
              color: 'green',
              emphasis: {
                focus: 'series'
              },

              data: []
            },
            {
              name: this.$t("paymentTypeOptions.debt"),
              type: 'bar',
              label: this.$t("paymentTypeOptions.debt"),
              color: '#f96868',
              emphasis: {
                focus: 'series'
              },
              data: []
            },
          ]
        }
      },
    }
  },
  methods: {
    getYears()
    {
      this.$loading(true);
      this.$Axios.get(`${this.$hostname}/api/years`, this.config())
        .then((res) =>
        {
          this.years = res.data;
          this.getMonths(this.years.filter(x => x.selected)[0].year);
          this.$loading(false);
        })
        .catch((err) =>
        {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: 'error',
          });
        });
    },

    getMonths(year)
    {
      this.$Axios.get(`${this.$hostname}/api/yearMonths?year=${year}`, this.config())
        .then((res) =>
        {
          this.months = res.data;
          if (this.months)
          {
            this.months.forEach(e =>
            {
              this.multiBar.option.xAxis[0].data.push(e.month.nameAr);
              this.multiBar.option.series[0].data.push(e.totalPaid);
              this.multiBar.option.series[1].data.push(e.actualDebt);

            });
          }

        })
        .catch((err) =>
        {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: 'error',
          });
        });
    }
  },
  mounted()
  {
    this.getYears();
  }

}
</script>