<template>
    <div>
      <div
        class="modal fade"
        id="CreateAttachmentModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("newAttachment") }}
              </h5>
              <button
              style="margin: 0;"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="form">
              <div class="modal-body">
                <div class="row">
                  <div class="col col-lg-12 col-12">
                    <vue-dropzone
                      ref="myVueDropzone"
                      id="myVueDropzone"
                      :options="dropzoneOptions"
                      @vdropzone-file-added="handleFileAdded"
                    >
                      <div class="dropzone">
                        <div class="dz-message">
                          {{ $t("dropFile") }}
                        </div>
                      </div>
                    </vue-dropzone>
                    <small class="text-danger d-block" v-if="files.length > 1"
                      >*{{ $t("uploadOne") }}</small
                    >
                    <button
                      v-if="fileDroped"
                      @click="removeFiles"
                      class="btn btn-danger mt-1"
                    >
                      {{ $t("removeFile") }}
                    </button>
                  </div>
                  <!-- <div class="col col-lg-12 col-12 mt-3">
                    <textarea
                      v-model="attachmentBody.description"
                      required
                      class="form-control"
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      :placeholder="'* ' + $t('writeAttachmentDes').toString()"
                    ></textarea>
                  </div> -->
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="close-attachment-btn"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {{ $t("purchase.close") }}
                </button>
                <button
                  class="btn btn-primary"
                  @click="addAttachment"
                  :disabled="files.length > 1"
                >
                  {{ $t("purchase.save") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import VueDropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import { post } from "@/api";
  import $ from "jquery";
  export default {
    components: {
      VueDropzone,
    },
    props: {
      type: {
        type: String,
        required: true,
      },
      entityId: {
        type: String,
        required: true,
      },
      onAttachmentCreation: {
        type: Function,
        required: true,
      },
    },
  
    data() {
      return {
        files: [],
        dropzoneOptions: {
          maxFiles: 1,
          url: "/upload",
          maxFilesize: 2,
          acceptedFiles: ".xlsx, .pdf, .jpg, .jpeg, .png",
        },
        formFile: new FormData(),
        fileDroped: null,
  
        attachmentBody: {
          attachmentType: parseInt(this.type),
          entityId: parseInt(this.entityId),
          description: null,
        },
  
        attachments: null,
        selectedAttachment: null,
      };
    },
    methods: {
      removeFiles() {
        this.$refs.myVueDropzone.removeAllFiles();
        this.fileDroped = null;
        this.files = [];
      },
      
      handleFileAdded(file) {
        this.fileDroped = null;
        this.formFile = new FormData();
        this.files.push(file);
        // Get the uploaded file data
        const uploadedFile = file;
        this.fileDroped = uploadedFile;
        // Create a new form file object
  
        this.formFile.append("file", uploadedFile, file.name);
      },
  
      resetProperties() {
        this.formFile = new FormData();
        this.attachmentBody = {
          attachmentType: parseInt(this.type),
          entityId: parseInt(this.entityId),
          description: null,
        };
      },
      async addAttachment() {
        if (!this.fileDroped) {
          this.$toast.open({
            message: this.$t("selectAFile"),
            type: "error",
          });
          return;
        }
        
        this.$loading(true);
        this.attachmentBody.entityId = parseInt(this.entityId)
        for (const key in this.attachmentBody) {
          if (key !== "image") {
            this.formFile.append(key, this.attachmentBody[key]);
          }
        }
        var URL = "/api/uploader";
        await post(URL, this.formFile);
        this.resetProperties();
        this.removeFiles();
        $("#close-attachment-btn").click();
        this.onAttachmentCreation();
        this.$loading(false);
      },
    },
  };
  </script>
  
  <style>
  .dropzone {
    border: 2px dashed #999;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    color: #999;
    cursor: pointer;
  }
  </style>