<style scoped>
.remove-top-margin {
  margin-top: -15px;
}
</style>
<template>
  <div v-if="expenses">
    <h1
      class="h3 mb-3"
      :class="
        $store.state.isDarkMode ? 'dark-active-text-color' : 'light-active-text-color'
      "
    >
      {{
        this.$route.params.isExpense == "true"
          ? $t("expenses.title")
          : $t("imports.title")
      }}
      {{ cleanDate(expenses[0].date) }}
    </h1>
    <!-- Add Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <form class="my-4 forms-sample" @submit.prevent="postTransaction()">
              <div class="form-group remove-top-margin">
                <label for="exampleInput1000">{{
                  $t("addUserTransaction.expenseType")
                }}</label>
                <select
                  v-if="expenseTypes && expenseTypes.items.length > 0"
                  id="exampleInput1000"
                  v-model="expenseTransactionBody.expenseType"
                  class="form-control text-dark"
                >
                  <option
                    :value="type.title"
                    v-for="type in expenseTypes.items"
                    :key="type.id"
                  >
                    {{ type.title }}
                  </option>
                </select>
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput3">{{ $t("addUserTransaction.amount") }}</label>
                <input
                  class="form-control"
                  id="exampleInput3"
                  type="number"
                  min="0"
                  v-model.number="expenseTransactionBody.amount"
                />
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput4">{{
                  $t("addUserTransaction.currencyType")
                }}</label>
                <select
                  v-model="expenseTransactionBody.currencyType"
                  required
                  class="form-control text-dark"
                  id="exampleInput4"
                >
                  <option value="1">{{ $t("paymentTypeOptions.dinnar") }}</option>
                  <option value="2">{{ $t("paymentTypeOptions.dollar") }}</option>
                </select>
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput6">{{ $t("addUserTransaction.date") }}</label>
                <input
                  id="exampleInput6"
                  class="form-control"
                  type="date"
                  v-model="expenseTransactionBody.date"
                />
              </div>
              <div class="form-group remove-top-margin">
                <label for="exampleInput7">{{ $t("addUserTransaction.note") }}</label>
                <input
                  id="exampleInput7"
                  class="form-control"
                  type="text"
                  v-model="expenseTransactionBody.note"
                />
              </div>
              <button type="submit" class="btn btn-primary me-2" :disabled="isLoading">
                <div
                  class="spinner-border text-light ml-3"
                  v-if="isLoading"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>

                <span v-else>
                  {{
                    expenseTransactionBody.id ? $t("dropdownOptions.edit") : $t("submit")
                  }}
                </span>
              </button>
              <button
                data-bs-dismiss="modal"
                aria-label="Close"
                class="btn btn-light modalCloseBtn"
              >
                {{ $t("close") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-1">
          <div class="card-body">
           
            <div class="row my-2">
              <div class="col col-lg-4 col-md-4 col-12 mt-2">
                <div class="card rounded-2 shadow-sm" style="background-color: #c44d56">
                  <div class="card-body text-light">
                    <h6 class="fw-bold">
                      {{ $t("totalExpensesDinnar") }}
                    </h6>
                    <div class="row">
                      <div class="col-10" style="font-size: 30px">
                        {{ totalDinnar.toLocaleString() }}
                        <span>{{ $t("currency.main") }}</span>
                      </div>
                      <div class="col-2">
                        <i class="mdi mdi-cash-multiple" style="font-size: 30px"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col-lg-4 col-md-4 col-12 mt-2">
                <div class="card rounded-2 shadow-sm" style="background-color: #c44d56">
                  <div class="card-body text-light">
                    <h6 class="fw-bold">
                      {{ $t("totalExpensesDollar") }}
                    </h6>
                    <div class="row">
                      <div class="col-10" style="font-size: 30px">
                        {{ totalDollar.toLocaleString() }}
                        <span>{{ $t("currency.secondary") }}</span>
                      </div>
                      <div class="col-2">
                        <i class="mdi mdi-cash-multiple" style="font-size: 30px"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col-lg-4 col-12 d-flex align-items-end">
                <button class="btn btn-primary text-white btn-sm rounded-1" v-print="'#printMeTransactions'">
                  {{ $t("exportOptions.print") }}
                </button>
              </div>
            </div>
            <div class="table-responsive" v-if="expenses && expenses.length > 0" id="printMeTransactions" dir="rtl">
              <table class="table table-hover my-0 table-striped">
                <thead style="background-color: #222e3c">
                  <tr class="text-light text-center">
                    <th>#</th>
                    <th>{{ $t("expenses.type") }}</th>
                    <th>{{ $t("expenses.amount") }}</th>
                    <th>{{ $t("expenses.date") }}</th>
                    <th>{{ $t("expenses.note") }}</th>
                    <th class="hide-print"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(expense, index) in expenses"
                    :key="expense.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text-bold">{{ expense.expenseType }}</td>
                    <td>
                      <div class="badge badge-opacity-danger me-3">
                        {{ expense.amount.toLocaleString() }}
                        <span>{{ getCurrencyText(expense.currencyType) }} </span>
                      </div>
                    </td>
                    <td>{{ cleanDate(expense.date) }}</td>
                    <td>{{ expense.note }}</td>
                    <td class="hide-print">
                      <button
                        class="btn btn-primary btn-sm rounded-1"
                        @click="assignBody(expense)"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        {{ $t("edit") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post } from "@/api";
import $ from "jquery";
export default {
  name: "SingleDayReport",
  created() {
    if (!this.checkLogin()) {
      this.$router.push("/login");
      return;
    }
  },
  computed: {
    totalDinnar() {
      if (!this.expenses) {
        return 0;
      }

      return this.expenses.reduce((total, expense) => {
        if (expense.currencyType === 1) {
          return total + expense.amount;
        }
        return total;
      }, 0);
    },
    totalDollar() {
      if (!this.expenses) {
        return 0;
      }

      return this.expenses.reduce((total, expense) => {
        if (expense.currencyType === 2) {
          return total + expense.amount;
        }
        return total;
      }, 0);
    },
  },
  data() {
    return {
      expenses: null,
      expenseTypes: null,
      isLoading: false,

      expenseTransactionBody: {
        id: 0,
        amount: 0,
        transactionType: "expense",
        currencyType: 1,
        paymentType: 2,
        note: null,
        date: new Date(),
        expenseType: null,
      },
    };
  },
  methods: {
    async loadExpenses() {
      this.$loading(true);
      const URL = `/api/Expenses/GetSpecificDayExpenses?isExpense=${this.$route.params.isExpense}&year=${this.$route.params.year}&month=${this.$route.params.month}&day=${this.$route.params.day}`;
      this.expenses = await get(URL);
      this.$loading(false);
    },
    assignBody(expense) {
      this.expenseTransactionBody = {
        id: expense.id,
        amount: expense.amount,
        transactionType: expense.transactionType,
        currencyType: expense.currencyType,
        paymentType: 2,
        note: expense.note,
        date: this.formatDate(expense.date),
        expenseType: expense.expenseType,
      };
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    async loadExpenseTypes() {
      this.$loading(true);
      const URL = `/api/expenseTypes?isExpense=${this.$route.params.isExpense}`;
      this.expenseTypes = await get(URL);
      this.$loading(false);
    },
    async postTransaction() {
      this.$loading(true);
      this.isLoading = true;
      this.expenseTransactionBody.paymentType = parseInt(
        this.expenseTransactionBody.paymentType
      );
      this.expenseTransactionBody.currencyType = parseInt(
        this.expenseTransactionBody.currencyType
      );
      this.expenseTransactionBody.transactionType =
        this.$route.params.isExpense == "true" ? "expense" : "import";
      const URL = `/api/userTransactions`;
      await post(URL, this.expenseTransactionBody);
      await this.loadExpenses();
      $(".modalCloseBtn").trigger("click");
      this.$loading(false);
      this.isLoading = false;
    },
  },

  async mounted() {
    await this.loadExpenses();
    await this.loadExpenseTypes();
  },
};
</script>
<style scoped>
@media print {
  .hide-print {
    display: none !important;
  }
}
</style>
